<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Creating build color</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Main info</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <f-input-text
                v-model="color.ms_title"
                :disabled="loading"
                title="Color name (from MS D)"
              />

              <f-input-text
                v-model="color.title"
                :disabled="loading"
                title="Colorname (for web & app)"
              />

              <f-select
                v-model="color.group"
                :disabled="loading"
                :options="groups"
                title="Color group"
                @onSearch="setColorGroup"
              />

              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>Color sphere img:</span>
                </div>

                <div class="main__content-item_r">
                  <media-uploader
                    :key="`color_image-${nonce}`"
                    :auto-processing="true"
                    :media="color.image || null"
                    :multiple="false"
                    :per-file-upload="false"
                    :processing-u-r-l="dropzoneSettings.url"
                    field-name="file"
                    footer-text="Drag&drop or <u>click</u> here to upload image sphere (jpg 3000x4000 up to 15MB)"
                    type="image"
                    @onRemove="removeColor"
                    @onSuccessResponse="setColorImage"
                  />
                </div>
              </div>

              <f-input-text
                v-model="color.price"
                title="Color price, €"
                type="number"
              />

              <f-input-checkbox v-model="color.custom" title="Custom" />
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Save color</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Text from "@/components/inputs/Text";
import Select from "@/components/inputs/Select";
import { mapGetters } from "vuex";
import CheckBox from "@/components/inputs/CheckBox";
import MediaUploader from "@/components/inputs/MediaUploader.vue";
import { API_ENDPOINT, STORAGE_ENDPOINT } from "@/utils/api";

require("verte/dist/verte.css");

export default {
  name: "ManageBuildColor",
  components: {
    MediaUploader,
    CRMLayout,
    "f-input-text": Text,
    "f-select": Select,
    "f-input-checkbox": CheckBox,
  },
  data() {
    return {
      nonce: 1,
      color: {
        id: null,
        ms_title: null,
        title: null,
        image: null,
        price: 0,
        group: {
          id: null,
          title: null,
        },
        custom: false,
      },
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      groups: "colors/groups",
    }),
    dropzoneSettings() {
      return {
        url: API_ENDPOINT + "v2/builds/media",
      };
    },
  },
  mounted() {
    this.loadGroups();
  },
  methods: {
    loadColor() {
      this.$store
        .dispatch("colors/loadSingle", this.$route.params.id)
        .then(result => {
          if (result) {
            for (let key of Object.keys(result)) {
              // eslint-disable-next-line
              if (this.color.hasOwnProperty(key)) {
                this.color[key] = result[key];
              }
            }

            console.log(this.color);
          }
          this.loading = false;
        })
        .catch(() => {
          this.$root.$emit("modalShow", {
            text: "Something went wrong. Try later",
            type: "error",
          });
        });
    },
    loadGroups() {
      this.$store
        .dispatch("colors/loadGroups")
        .then(() => {
          if (this.$route.params.id !== "create") {
            this.loadColor();
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.$root.$emit("modalShow", {
            text: "Something went wrong. refresh page and try again. pls",
            type: "error",
          });
        });
    },
    setColorGroup(value) {
      this.color.group.title = value;
    },
    removeColor() {
      this.color.image = null;
    },
    setColorImage(event) {
      if (event.length > 0) {
        this.color.image = STORAGE_ENDPOINT + event[0];
      }
    },
    save() {
      let data = {
        ...this.color,
        ...{ group: this.color.group.title },
      };

      data.image = data.image ? data.image.replace(STORAGE_ENDPOINT, "", data.image) : null;

      this.$store
        .dispatch("colors/save", data)
        .then(result => {
          if (this.color.id === null) {
            this.$router.push("/colors/" + result.data.id).then(() => {
              this.loadGroups();
            }).catch(() => {
            });
          } else {
            this.loadGroups();
          }
          this.$root.$emit("modalShow", {
            text: "Color saved",
            type: "info",
          });
        })
        .catch(err => {
          this.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
  },
};
</script>
