<template>
  <CRMLayout>
    <template v-slot:header-back-btn>
      <button class="btn btn-gray add-models-btn" @click="createMedia()">Create Media</button>

      <button class="btn btn-gray add-models-btn" @click="createAlbum()">Create Album</button>
    </template>

    <template v-slot:content>
      <div class="main__c">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Media library</h5>
          </div>
          <!-- .main__headline -->

          <div class="table__products table">
            <div class="thead">
              <div class="tr">
                <div class="th"></div>
                <div class="id th">
                  <span>ID</span>
                </div>
                <div class="th">
                  <span>Category</span>
                </div>
                <div class="th">
                  <span>Albums</span>
                </div>
                <div class="th">Items</div>
                <div class="th publish">
                  <span>Activity</span>
                </div>
                <div class="th pen"></div>
              </div>
            </div>

            <div class="tbody">
              <draggable v-model="items" :sort="true" tag="div">
                <div v-for="media in items" :key="media.id">
                  <div class="tr">
                    <div class="td">
                      <div class="btn-burger centered pointer"></div>
                    </div>
                    <div class="id td">{{ media.id }}</div>
                    <div class="td d-flex items-center">
                      <a class="min-216" href="#" @click.prevent="showMedia(media.id)">{{ media.title }}</a>
                      <button :class="{'active': expandedMedia.includes(media.id)}" class="btn-arr"
                              @click="toggleMedia(media.id)">
                        <img
                          alt="arrow"
                          src="../assets/img/icons/arrow-bottom.svg"
                        />
                      </button>
                    </div>
                    <div class="td">{{ media.albums_count }}</div>
                    <div class="td">{{ media.files_count }}</div>
                    <div class="td publish">
                      <button
                        :class="{'active': media.active, 'disabled': disabledItems.includes(media.id)}"
                        class="btn-activity mmt-1-5 mmb-1-5"
                        @click="toggleMediaActive(media.id, media.active)"
                      >
                        {{ media.active ? "Yes" : "No" }}
                      </button>
                    </div>
                    <div class="td pen"></div>
                  </div>
                  <template v-if="media.albums.length && expandedMedia.includes(media.id)">
                    <media-tree v-bind:albums="media.albums" v-bind:filters="filters" v-bind:show-album="showAlbum" />
                  </template>
                </div>
              </draggable>
            </div>
          </div>
        </div>
        <!-- .main__c-in -->
      </div>
      <!-- .main__c -->

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Filter</h5>
        </div>
        <!-- .main__headline -->

        <div class="main__r-in">
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Filter archived</h5>
            </div>
            <!-- .inp-group__headline -->
            <div class="inp-group__in">
              <div class="inp-group__radio-wrapper gray">
                <div class="inp-group__radio ">
                  <input
                    id="on"
                    v-model="filters.withTrashed"
                    :value="true"
                    checked
                    name="withTrashed"
                    type="radio"
                  />
                  <label for="on">ON</label>
                </div>
                <!-- .inp-group__radio -->
                <div class="inp-group__radio ">
                  <input
                    id="off"
                    v-model="filters.withTrashed"
                    :value="false"
                    name="withTrashed"
                    type="radio"
                  />
                  <label for="off">OFF</label>
                </div>
                <!-- .inp-group__radio -->
              </div>
              <!-- .inp-group__radio-wrapper -->
            </div>
            <!-- .inp-group__in -->
          </div>
          <!-- .inp-group__wrapper -->

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-sm btn-bold"
              @click="resetFilters()"
            >
              Reset filter
            </button>
          </div>
          <!-- .btn__wrapper -->
        </div>
        <!-- .main__r-in -->
      </div>
      <!-- .main__r -->

      <modal
        :adaptive="true"
        :clickToClose="true"
        :scrollable="false"
        :shiftY="0.1"
        height="auto"
        name="edit-album"
        width="70%"
      >
        <div class="p-40">
          <div class="w-100 ">
            <h3 v-if="albumEdit.languages" class="bold mb-20">
              {{ albumEdit.languages.en.title }}
            </h3>
          </div>

          <div class="w-100 mb-20 vh80 scrollbar-wrap">
            <media-item-component v-bind:album="albumEdit" v-bind:filters="filters" />
          </div>
          <div class="w-100 text-right">
            <button class="btn btn-gray mr-20" @click="deleteAlbum">Delete</button>

            <button class="btn btn-white mr-20" @click="hideAlbum">Close</button>

            <button class="btn btn-green h-32" @click="saveAlbum">Save</button>
          </div>
        </div>
      </modal>

      <modal
        :adaptive="true"
        :clickToClose="false"
        :scrollable="false"
        :shiftY="0.1"
        height="auto"
        name="edit-media"
        width="70%"
      >
        <div class="p-40">
          <div class="w-100 mb-20">
            <h3 v-if="mediaEdit.languages" class="bold">
              {{ mediaEdit.languages.en.title }}
            </h3>
          </div>
          <div class="w-100">
            <div class="tab-pane mb-4">
              <div class="main__content-in p-0">

                <ul class="nav nav-tabs list-unstyled ml-0 mr-0 mb-2">
                  <li
                    v-for="(language, index) in languages"
                    :key="index"
                    :class="{ active: isActiveLang(language) }"
                  >
                    <a @click="changeLang(language)">{{ language }}</a>
                  </li>
                </ul>


              </div>
            </div>
            <div class="inp-group__wrapper inp-group__from-to mb-4">
              <div class="inp-group__headline">
                <h5>Name:</h5>
              </div>
              <div class="main__content-item_bottom">
                <div
                  :class="{ 'inp-error': errors.mediaTitle }"
                  class="inp-group inp-group__textarea h-xl"
                  style="max-width: 600px"
                >
                  <input
                    ref="media_title"
                    v-model.trim="mediaTitle"
                    class="w-100"
                    type="text"
                  />
                  <p v-if="errors.mediaTitle" class="inp-error__desc">
                    {{ errors.mediaTitle }}
                  </p>
                </div>
              </div>
            </div>
            <div class="inp-group__wrapper inp-group__from-to mb-4">
              <div class="inp-group__headline">
                <h5>Published:</h5>
              </div>
              <div class="inp-group__in">
                <div class="inp-group__radio-wrapper gray" style="max-width: 280px">
                  <div class="inp-group__radio ">
                    <input
                      id="on1"
                      v-model="mediaEdit.active"
                      :value="true"
                      name="active"
                      type="radio"
                    />
                    <label for="on1">ON</label>
                  </div>
                  <div class="inp-group__radio ">
                    <input
                      id="off1"
                      v-model="mediaEdit.active"
                      :value="false"
                      name="active"
                      type="radio"
                    />
                    <label for="off1">OFF</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-4" style="max-width: 848px;">
              <div class="col-6">
                <div class="inp-group__wrapper">
                  <div class="inp-group__headline">
                    <h5>Start activity</h5>
                  </div>

                  <div class="inp-group__in">
                    <div class="inp-group">
                      <date-picker
                        v-model="mediaEdit.start_activity"
                        :format="'YYYY/MM/DD'"
                        size="small"
                        type="date"
                        valueType="YYYY-MM-DD"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="inp-group__wrapper">
                  <div class="inp-group__headline">
                    <h5>End activity</h5>
                  </div>

                  <div class="inp-group__in">
                    <div class="inp-group">
                      <date-picker
                        v-model="mediaEdit.end_activity"
                        :format="'YYYY/MM/DD'"
                        size="small"
                        type="date"
                        valueType="YYYY-MM-DD"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="mediaEdit.first_activity" class="publication-text mb-4">
              <h5>First publication date</h5>
              <p class="data ml-0">{{ mediaEdit.first_activity }}</p>
            </div>
          </div>
          <div class="w-100 text-right">
            <button v-if="mediaEdit.deleted_at" class="btn btn-gray mr-20" @click="restoreMedia">Restore</button>
            <button v-else class="btn btn-gray mr-20" @click="deleteMedia">Delete</button>

            <button class="btn btn-white mr-20" @click="hideMedia">Close</button>

            <button class="btn btn-green h-32" @click="saveMedia">Save</button>
          </div>
        </div>
      </modal>

    </template>
  </CRMLayout>
</template>

<script>
import { mapState } from "vuex";
import CRMLayout from "./CRMLayout";
import draggable from "vuedraggable";
import MediaTree from "./MediaTree";
import DatePicker from "vue2-datepicker";
import MediaItemComponent from "./partials/MediaItemComponent";
import { random } from "@/store/helpers/stringHelper";

export default {
  name: "CRMDashBoard",
  components: {
    MediaItemComponent,
    MediaTree,
    CRMLayout,
    DatePicker,
    draggable,
  },
  computed: {
    ...mapState({
      languages: store => store.languages.list,
    }),
    items: {
      get() {
        return this.$store.state.mediaList.items;
      },
      set(items) {
        items.forEach(function(item, index) {
          item.sort_order = index;
        });

        let $this = this;
        this.$store
          .dispatch("mediaList/update_sort_order", items)
          .finally(() => $this.loadItems());
      },
    },
    mediaTitle: {
      get() {
        // eslint-disable-next-line
        if (this.mediaEdit.languages && this.mediaEdit.languages.hasOwnProperty(this.activeLang)) {
          return this.mediaEdit.languages[this.activeLang].title;
        }
        return null;
      },
      set(value) {
        // eslint-disable-next-line
        if (this.mediaEdit.languages && this.mediaEdit.languages.hasOwnProperty(this.activeLang)) {
          this.mediaEdit.languages[this.activeLang].title = value;
        } else {
          this.mediaEdit.languages = {
            ...this.mediaEdit.languages,
            [this.activeLang]: { title: value },
          };
        }
      },
    },
  },
  data() {
    return {
      dragging: false,
      activeLang: "en",
      errors: [],
      isUploading: false,
      filters: {
        withTrashed: null,
      },
      albumEdit: {},
      mediaEdit: {},
      expandedMedia: [],
      disabledItems: [],
    };
  },
  created() {
    this.loadItems();
  },
  mounted() {
    let ctx = this;
    this.$root.$on("vdropzone-processing", function() {
      ctx.isUploading = true;
    });
    this.$root.$on("vdropzone-success", function() {
      ctx.isUploading = false;
    });
  },

  methods: {
    loadItems() {
      this.$store.dispatch("mediaList/get_media_request", this.filters);
    },
    resetFilters() {
      this.filters.withTrashed = null;
    },
    showAlbum(id) {
      this
        .$store
        .dispatch("mediaItem/get_album_by_id", id)
        .then(response => this.albumEdit = response.data)
        .then(() => this.$modal.show("edit-album"));
    },
    showMedia(id) {
      this
        .$store
        .dispatch("mediaItem/get_media_by_id", id)
        .then(response => this.mediaEdit = response.data)
        .then(() => this.$modal.show("edit-media"));
    },
    createAlbum() {
      this.albumEdit = {
        id: 0,
        files: [],
        storage_path_id: "album-" + random(5),
        cover_file: null,
        cover_image: null,
        url_hires_arch: null,
        languages: {
          en: {
            title: "",
            content: "",
          },
          es: {
            title: "",
            content: "",
          },
          de: {
            title: "",
            content: "",
          },
          fr: {
            title: "",
            content: "",
          },
          ru: {
            title: "",
            content: "",
          },
        },
        zip: null,
        parent_id: null,
        media_id: null,
        act_parent: "",
        children: [],
      };
      this.$modal.show("edit-album");
    },
    createMedia() {
      this.mediaEdit = {
        id: 0,
        active: false,
        start_activity: null,
        end_activity: null,
        languages: {
          en: {
            title: "",
          },
          es: {
            title: "",
          },
          de: {
            title: "",
          },
          fr: {
            title: "",
          },
          ru: {
            title: "",
          },
        },
      };
      this.$modal.show("edit-media");
    },
    hideAlbum() {
      this.albumEdit = {};
      this.$modal.hide("edit-album");
    },
    hideMedia() {
      this.mediaEdit = {};
      this.$modal.hide("edit-media");
    },
    saveAlbum() {
      if (this.albumEdit.cover_file) {
        let data = new FormData();
        data.append("cover_file", this.albumEdit.cover_file);
        this
          .$store
          .dispatch("mediaItem/upload_cover", { data: data, id: this.albumEdit.storage_path_id })
          .then(response => {
            this.albumEdit.cover_image = response.data.name;
            this
              .$store
              .dispatch("mediaItem/update_album", this.albumEdit)
              .then(() => this.$modal.hide("edit-album"))
              .then(() => this.loadItems());
          });
      } else {
        this
          .$store
          .dispatch("mediaItem/update_album", this.albumEdit)
          .then(() => this.$modal.hide("edit-album"))
          .then(() => this.loadItems());
      }
    },
    saveMedia() {
      this
        .$store
        .dispatch("mediaItem/update_media", this.mediaEdit)
        .then(() => this.$modal.hide("edit-media"))
        .then(() => this.loadItems());
    },
    changeLang(language) {
      this.activeLang = language;
    },
    isActiveLang(language) {
      return this.activeLang === language;
    },
    toggleMedia(id) {
      if (this.expandedMedia.includes(id)) {
        this.expandedMedia = this.expandedMedia.filter(
          (item) => item !== id,
        );
      } else {
        this.expandedMedia.push(id);
      }
    },
    toggleMediaActive(id, active) {
      if (!this.disabledItems.includes(id)) {
        this.disabledItems.push(id);
        this
          .$store
          .dispatch("mediaItem/update_media", {
            id: id,
            active: !active,
          })
          .then(() => this.loadItems())
          .finally(() => {
            setTimeout(() => {
              this.disabledItems = this.disabledItems.filter(item => item !== id);
            }, 200)
          });
      }
    },
    deleteMedia() {
      this.$dialog
        .confirm("Are you sure you want to delete media?")
        .then(() => this.$modal.hide("edit-media"))
        .then(() => {
          this.$store
            .dispatch("mediaItem/delete_request", this.mediaEdit)
            .then(() => {
              this.loadItems();
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });

              this.loadItems();
            });
        })
        .catch(() => {
        });
    },
    restoreMedia() {
      this.$dialog
        .confirm("Are you sure you want to restore media?")
        .then(() => this.$modal.hide("edit-media"))
        .then(() => {
          this
            .$store
            .dispatch("mediaItem/update_media", {
              id: this.mediaEdit.id,
              deleted_at: null,
            })
            .then(() => this.loadItems());
        })
        .catch(() => {
        });
    },
    deleteAlbum() {
      this.$dialog
        .confirm("Are you sure you want to delete album?")
        .then(() => this.$modal.hide("edit-album"))
        .then(() => {
          this
            .$store.dispatch("mediaItem/deleteAlbum", this.albumEdit.id)
            .then(() => {
              this.$store.dispatch("mediaList/get_media_request", this.filters);
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });

              this.$store.dispatch("mediaList/get_media_request", this.filters);
            });
        })
        .catch(() => {
        });
    },
  },
  watch: {
    sortEnable: function(val) {
      if (val) {
        this.enableSort();
      } else {
        this.disableSort();
      }
    },
    filterReseted: function(val) {
      this.sortEnable = val;
    },
    currentItemType(currentItemType) {
      this.sortEnable = currentItemType.code === "news";
      this.updateList = true;
      this.loadPosts();
    },
    filters: {
      deep: true,
      handler() {
        this.loadItems();
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.table {
  .td {
    box-sizing: border-box;
  }
}

.min-216 {
  min-width: 216px;
  padding-right: 8px;
  display: inline-block;
}

.btn-arr {
  width: 24px;
  height: 24px;
  border: none;
  background: transparent;
  cursor: pointer;
  margin: -3px 0;

  &:not(.active) {
    transform: rotate(180deg);
  }

  img {
    width: 100%;
    height: 100%;
    filter: brightness(0) saturate(100%)
  }
}
</style>
