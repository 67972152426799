<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Admin\Support category update</span>
    </template>

    <template v-slot:content>
      <div class="main__c bg-transparent">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Support category</h5>
          </div>

          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.formNamePrefix"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div class="main__content tab-pane">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="language.formNamePrefix"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.category[language.formNamePrefix + 'title']
                            "
                            autofocus
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="!currentParentCategory"
                      class="main__content-item"
                      style="margin-bottom: 15px"
                    >
                      <div class="main__content-item_l">
                        <span>Description:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <textarea
                            v-model="
                              $data.category[language.formNamePrefix + 'desc']
                            "
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Parent category:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group__dropdown inp-group__xl">
                        <v-select
                          v-model="currentParentCategory"
                          :options="categories"
                          label="label"
                          placeholder="Select parent category(optional)"
                        />
                      </div>
                    </div>
                  </div>

                  <div v-if="!currentParentCategory" class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Category header image:</span>
                    </div>

                    <div class="main__content-item_r">
                      <media-uploader
                        key="slider_image"
                        :auto-processing="false"
                        :media="category.img"
                        :multiple="false"
                        footer-text="Drag&drop or <u>click</u> here to upload image (jpg 512x512 up to 15MB)"
                        type="image"
                        @onRemove="deleteTempCarouselImage()"
                        @onUploadCompleted="saveTempCarouselImage($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Publication settings</h5>
        </div>

        <div class="main__r-in">
          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Published</h5>
            </div>

            <div class="inp-group__in">
              <div class="inp-group__radio-wrapper">
                <div class="inp-group__radio">
                  <input
                    id="on"
                    v-model="category.active"
                    :value="1"
                    name="radio"
                    type="radio"
                  />
                  <label for="on">ON</label>
                </div>

                <div class="inp-group__radio">
                  <input
                    id="off"
                    v-model="category.active"
                    :value="0"
                    name="radio"
                    type="radio"
                  />
                  <label for="off">OFF</label>
                </div>
              </div>
            </div>
          </div>

          <div class="btn__wrapper">
            <button
              class="btn btn-white btn-reset btn-fw btn-bold btn-sm"
              @click="save"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import { STORAGE_ENDPOINT } from "@/utils/api";
import MediaUploader from "@/components/inputs/MediaUploader.vue";

export default {
  name: "CRMSupportCategoriesCreate",
  components: { MediaUploader, CRMLayout, vSelect },
  data() {
    return {
      category: {
        id: null,
        title: null,
        es_title: null,
        de_title: null,
        fr_title: null,
        ru_title: null,
        desc: null,
        es_desc: null,
        de_desc: null,
        fr_desc: null,
        ru_desc: null,
        parent_id: null,
        img: null,
        active: false,
      },
      img: null,
      categories: [],
      currentParentCategory: null,
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: "",
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_",
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_",
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_",
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_",
        },
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: "",
      },
    };
  },
  mounted() {
    this.loadCategories();
  },
  methods: {
    loadCategories() {
      let ctx = this;
      this.$store
        .dispatch("get_support_categories_request", { withChildren: false })
        .then(response => {
          let categories = response.data.categories.filter(category => {
            return !category.parent_id;
          });
          ctx.categories = categories.map(category => {
            return {
              code: category.id,
              label: category.title,
            };
          });
          this.loadCategory();
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    loadCategory() {
      let ctx = this;
      this.$store
        .dispatch("get_support_category_request", this.$route.params.id)
        .then(response => {
          let resp = response.data.category;
          ctx.categories = ctx.categories.filter(category => {
            return category.code !== resp.id;
          });
          for (let categoryKey of Object.keys(resp)) {
            // eslint-disable-next-line
            if (ctx.category.hasOwnProperty(`${categoryKey}`)) {
              if (categoryKey === "img") {
                if (resp[categoryKey]) {
                  ctx.category.img = STORAGE_ENDPOINT + resp[categoryKey];
                }
              } else {
                ctx.category[`${categoryKey}`] = resp[categoryKey];
              }
            }

            if (categoryKey === "parent") {
              if (resp[categoryKey]) {
                ctx.currentParentCategory = {
                  code: resp[categoryKey].id,
                  label: resp[categoryKey].title,
                };
              }
            }
          }
        })
        .catch(err => {
          this.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    saveTempCarouselImage(file) {
      this.img = file;
    },
    deleteTempCarouselImage() {
      this.category.img = null;
      this.img = null;
    },
    save() {
      let ctx = this;

      let data = new FormData();
      data.append("id", this.category.id);
      data.append("title", this.category.title);
      data.append("es_title", this.category.es_title);
      data.append("de_title", this.category.de_title);
      data.append("fr_title", this.category.fr_title);
      data.append("ru_title", this.category.ru_title);
      data.append("desc", this.category.desc);
      data.append("es_desc", this.category.es_desc);
      data.append("de_desc", this.category.de_desc);
      data.append("fr_desc", this.category.fr_desc);
      data.append("ru_desc", this.category.ru_desc);
      data.append("active", this.category.active);
      data.append(
        "parent_id",
        this.currentParentCategory ? this.currentParentCategory.code : null,
      );
      data.append("img", this.img);

      this.$store
        .dispatch("update_support_category_request", {
          data: data,
          id: ctx.category.id,
        })
        .then(() => {
          ctx.$router.push("/supports");
          ctx.$root.$emit("modalShow", {
            text: "Category successfully updated",
            type: "info",
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
  },
};
</script>

<style scoped>
.main__content-item.main__content-item_center {
  margin-bottom: 1.5rem;
}
</style>
