<template>
  <div
    :id="identifier"
    :class="{hovered: hovered}"
    class="media-uploader wrapper"
    @drop.prevent="handleDrop($event)"
    @dragover.prevent="setHover"
    @dragenter.prevent="checkDrop"
    @dragleave.prevent="hideHover"
  >
    <input
      :key="`media-uploader-${identifier}-${nonceStringValue}`"
      ref="fileInput"
      :multiple="multiple"
      class="file-input"
      type="file"
      @input="handleDrop($event, true)"
    >
    <div class="items-holder" @click="openFileDialog">
      <div v-for="(item, index) in items" :key="`media-item-${index}`" :class="{link: isLink(item)}" class="item">
        <template v-if="!isLink(item)">
          <div v-if="!item.loading" class="remove-icon" @click.prevent="removeItem(item)">&times;</div>
          <div class="preview">
            <div v-if="item.loading" class="loader-wrapper">
              <div
                :aria-valuenow="item.progressPercent"
                :style="progressPercentStyle(item)"
                aria-valuemax="100"
                aria-valuemin="0"
                role="progressbar"
              >
              </div>
            </div>

            <template v-if="!item.loading">
              <div v-if="item.errorExists" class="error">Error</div>
              <div v-if="isVideo(item)" class="video">
                <video
                  :ref="`videoItem-${index}`"
                  :src="itemSrc(item)"
                  muted
                  playsinline
                  @click.prevent="toggleVideo(index)"
                ></video>
              </div>
              <div v-if="isImage(item)" class="image">
                <img :src="itemSrc(item)" :alt="fileName || 'Dropzone Media'" class="image">
              </div>
              <div v-if="isDocument(item)" class="document">
                {{ fileExtension }}
              </div>
              <div v-if="isUnknown(item)" class="unknown">unknown</div>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div v-if="hasInfo" class="info" @click="openFileDialog" v-html="footerText"></div>
  </div>
</template>

<script>
import { createVideoURL, getFileCategory } from "@/utils/helper";

export default {
  name: "MediaUploader",
  props: {
    media: {
      type: [Array, String, File],
      required: false,
      default: () => [],
    },
    id: {
      required: false,
      default: null,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    footerText: {
      type: [String, null],
      required: false,
      default: null,
    },
    autoProcessing: {
      type: Boolean,
      required: false,
      default: false,
    },
    processingURL: {
      type: String,
      required: false,
      default: "",
    },
    requestHeaders: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: [String, null],
      required: false,
      default: null,
    },
    fieldName: {
      type: [String, null],
      required: false,
      default: "files",
    },
    perFileUpload: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      defaultHeaders: {
        "Accept": "application/json",
      },
      nonce: 1,
      hovered: false,
      localMedia: [],
    };
  },
  computed: {
    fileExtension() {
      if (this.fileName) {
        return this.fileName.split(".")[this.fileName.split(".").length - 1];
      }

      return "";
    },
    fileName() {
      let cm = null;
      if (this.localMedia && this.localMedia.length > 0) {
        cm = this.localMedia[0];
      }

      if (this.media && this.media.length > 0) {
        cm = this.media[0];
      }

      if (cm) {
        if (cm instanceof File) {
          return cm.name;
        } else {
          return cm;
        }
      }

      return "";
    },
    headers() {
      let headers = { ...this.defaultHeaders };

      if (this.requestHeaders !== null) {
        headers = { ...headers, ...this.requestHeaders };
      }

      return headers;
    },
    items() {
      if (this.localMedia.length) {
        return this.localMedia;
      }

      if (Array.isArray(this.media)) {
        return this.media;
      }

      return this.media ? [this.media] : [];
    },
    hasInfo() {
      return !!this.footerText;
    },
    identifier() {
      return this.id || `media-uploader-${this._uid}`;
    },
    nonceStringValue() {
      return this.nonce.toString();
    },
  },
  methods: {
    progressPercentStyle(item) {
      return `--value: ${item.progressPercent}`;
    },
    openFileDialog(event) {
      if (event.target.classList.contains("items-holder") || event.target.classList.contains("info")) {
        this.$refs.fileInput.click();
      }
    },
    uploadMedia(media) {
      if ((this.multiple && !this.perFileUpload) || !this.multiple) {
        const xhr = new XMLHttpRequest();
        const formData = new FormData();

        if (this.multiple) {
          for (let i = 0; i < media.length; i++) {
            if (media[i] instanceof File) {
              formData.append(`${this.fieldName}s[]`, media[i]);
            }
          }
        } else {
          formData.append(this.fieldName, media[0]);
        }

        xhr.open("POST", this.processingURL, true);

        for (const key of Object.keys(this.headers)) {
          xhr.setRequestHeader(key, this.headers[key]);
        }

        xhr.upload.onprogress = event => {
          if (event.lengthComputable) {
            this.localMedia = this.localMedia.map(item => {
              item.progressPercent = Math.round((event.loaded / event.total) * 100);
              return item;
            });
          }
        };

        xhr.onerror = () => {
          this.localMedia = this.localMedia.map(item => {
            item.loading = false;
            item.progressPercent = 0;
            item.errorExists = true;
            return item;
          });

          this.$root.$emit("modalShow", {
            text: "Error is happend. Some files not uploaded. Try again later",
            type: "error",
          });
        };

        xhr.onload = () => {
          if (xhr.status === 200 || xhr.status === 201) {
            if (this.multiple) {
              this.$emit("input", media);
              this.$emit("onUploadCompleted", media);
            } else {
              this.$emit("input", media[0]);
              this.$emit("onUploadCompleted", media[0]);
            }

            if (this.defaultHeaders.Accept === "application/json") {
              this.$emit("onSuccessResponse", JSON.parse(xhr.response));
            } else {
              this.$emit("onSuccessResponse", xhr.response);
            }

            this.$nextTick(() => {
              this.localMedia = [];
              this.nonce++;
            });
          } else {
            if (this.multiple) {
              this.$emit("input", []);
              this.$emit("onUploadCompleted", []);
            } else {
              this.$emit("input", null);
              this.$emit("onUploadCompleted", null);
            }

            if (this.defaultHeaders.Accept === "application/json") {
              this.$emit("onErrorResponse", JSON.parse(xhr.response));
            } else {
              this.$emit("onErrorResponse", xhr.response);
            }

            console.log("Error while upload files");

            this.nonce++;

            this.localMedia = this.localMedia.map(item => {
              item.loading = false;
              item.progressPercent = 0;
              return item;
            });
          }
        };

        xhr.send(formData);
      } else if (this.multiple && this.perFileUpload && this.autoProcessing) {
        for (let i = 0; i < media.length; i++) {
          if (media[i] instanceof File) {
            const xhr = new XMLHttpRequest();
            const formData = new FormData();

            formData.append(this.fieldName, media[i]);

            xhr.open("POST", this.processingURL, true);

            for (const key of Object.keys(this.headers)) {
              xhr.setRequestHeader(key, this.headers[key]);
            }

            xhr.upload.onprogress = event => {
              if (event.lengthComputable) {
                this.localMedia = this.localMedia.map(item => {
                  if (item === media[i]) {
                    item.loading = true;
                    item.progressPercent = Math.round((event.loaded / event.total) * 100);
                  }
                  return item;
                });
              }
            };

            xhr.onerror = () => {
              this.localMedia = this.localMedia.map(item => {
                if (item === media[i]) {
                  item.loading = false;
                  item.progressPercent = 0;
                  item.errorExists = true;
                }
                return item;
              });
            };

            xhr.onload = () => {
              if (xhr.status === 200 || xhr.status === 201) {
                this.$emit("input", media);
                this.$emit("onUploadCompleted", media);

                if (this.defaultHeaders.Accept === "application/json") {
                  this.$emit("onSuccessResponse", JSON.parse(xhr.response));
                } else {
                  this.$nextTick(() => {
                    this.$emit("onSuccessResponse", xhr.response);
                  });
                }

                this.$nextTick(() => {
                  this.localMedia = this.localMedia.filter(item => {
                    return item !== media[i];
                  });

                  this.nonce++;
                });
              } else {
                this.$emit("input", []);
                this.$emit("onUploadCompleted", []);

                if (this.defaultHeaders.Accept === "application/json") {
                  this.$emit("onErrorResponse", JSON.parse(xhr.response));
                } else {
                  this.$emit("onErrorResponse", xhr.response);
                }

                console.log("Error while upload files");

                this.nonce++;

                this.localMedia = this.localMedia.map(item => {
                  if (item === media[i]) {
                    item.loading = false;
                    item.progressPercent = 0;
                  }
                  return item;
                });
              }
            };

            xhr.send(formData);
          }
        }
      }
    },
    hideHover(event) {
      event.stopPropagation();
      event.preventDefault();

      this.hovered = false;
    },
    setHover(event) {
      event.stopPropagation();
      event.preventDefault();

      this.hovered = true;
    },
    checkDrop(event) {
      event.stopPropagation();
      event.preventDefault();
    },
    handleDrop(event, fromInput = false) {
      let files;

      if (fromInput) {
        files = event.target.files;
      } else {
        files = event.dataTransfer.files;
      }

      if (files.length > 0) {
        event.stopPropagation();
        event.preventDefault();

        for (let file of files) {
          file.loading = this.autoProcessing;
          file.percent = 0;

          if (this.type !== null) {
            if ((!this.multiple && this.localMedia.length === 0) || this.multiple) {

              if (this.type === "video" && this.isVideo(file)) {
                this.localMedia.push(file);
              }

              if (this.type === "image" && this.isImage(file)) {
                this.localMedia.push(file);
              }

              if (this.type === "document" && this.isDocument(file)) {
                this.localMedia.push(file);
              }
            }
          } else {
            if ((!this.multiple && this.localMedia.length === 0) || this.multiple) {
              this.localMedia.push(file);
            }
          }
        }

        if (this.localMedia.length !== files.length) {
          this.$root.$emit("modalShow", {
            text: "Some of uploaded files has wrong type. All other files added.",
            type: "error",
          });
        }

        if (this.localMedia.length > 0) {
          if (this.autoProcessing && this.processingURL) {
            this.uploadMedia(this.localMedia);
          } else {
            if (this.multiple) {
              this.$emit("input", this.localMedia);
              this.$emit("onUploadCompleted", this.localMedia);
            } else {
              this.$emit("input", this.localMedia[0]);
              this.$emit("onUploadCompleted", this.localMedia[0]);
            }
          }
        }
      }
    },
    removeItem(item) {
      if (this.multiple) {
        this.localMedia = this.localMedia.filter(mediaItem => mediaItem !== item);
        this.$emit("input", this.localMedia.filter(mediaItem => mediaItem !== item));
      } else {
        this.localMedia = [];
        this.$emit("input", null);
      }

      this.nonce++;

      this.$emit("onRemove", item);
    },
    toggleVideo(index) {
      if (this.$refs[`videoItem-${index}`] && this.$refs[`videoItem-${index}`][0]) {
        let videoTag = this.$refs[`videoItem-${index}`][0];
        if (videoTag.paused) {
          videoTag.play();
        } else {
          videoTag.pause();
        }
      }
    },
    itemSrc(item) {
      if (item instanceof File) {
        return createVideoURL(item);
      }

      return item;
    },
    isVideo(item) {
      return getFileCategory(item) === "video";
    },
    isImage(item) {
      return getFileCategory(item) === "photo";
    },
    isDocument(item) {
      return getFileCategory(item) === "document";
    },
    isUnknown(item) {
      return getFileCategory(item) === "unknown";
    },
    isLink(item) {
      return this.isUnknown(item) && !(item instanceof File);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes progress {
  0% {
    --percentage: 0;
  }
  100% {
    --percentage: var(--value);
  }
}

@property --percentage {
  syntax: '<number>';
  inherits: true;
  initial-value: 0;
}

.media-uploader {
  &.wrapper {
    background: gray;
    width: 100%;
    padding: 13px 0 0 13px;
    border: 2px dotted #e5e5e5;
    border-radius: 4px;
    color: #777;
    background: white;
    margin-bottom: 10px;
    transition: all 0.2s ease-in-out;

    &:hover,
    &.hovered {
      background: rgba(229, 229, 229, 0.73);
      cursor: pointer;
      border: 2px dotted #777;
    }

    .file-input {
      display: none;
    }

    .items-holder {
      height: 150px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .item {
        width: 150px;
        height: 150px;
        margin-right: 10px;
        background: white;
        position: relative;
        border-radius: 4px;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);

        &.link {
          display: none;
        }

        &:last-of-type {
          margin-right: 0;
        }

        .remove-icon {
          position: absolute;
          top: -1px;
          right: 6px;
          font-size: 38px;
          color: black;
          z-index: 7;
        }

        .preview {
          width: 100%;
          height: 100%;
          overflow: hidden;
          display: flex;

          .video {
            width: 100%;
            height: 100%;

            video {
              width: 100%;
              height: 100%;
            }
          }

          .image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            margin: auto;
          }

          .document {
            display: flex;
            width: 100%;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }

        .loader-wrapper {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          [role="progressbar"] {
            --percentage: var(--value);
            --primary: #C2E900;
            --secondary: #898c7b;
            --size: 100px;
            animation: progress 2s 0.5s forwards;
            width: var(--size);
            aspect-ratio: 1;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            display: grid;
            place-items: center;
          }

          [role="progressbar"]::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: conic-gradient(var(--primary) calc(var(--percentage) * 1%), var(--secondary) 0);
            mask: radial-gradient(white 55%, transparent 0);
            mask-mode: alpha;
            -webkit-mask: radial-gradient(#0000 55%, #000 0);
            -webkit-mask-mode: alpha;
          }

          [role="progressbar"]::after {
            counter-reset: percentage var(--value);
            content: counter(percentage) '%';
            font-family: Helvetica, Arial, sans-serif;
            font-size: calc(var(--size) / 5);
            color: var(--primary);
          }
        }
      }
    }

    .info {
      padding: 20px 10px 10px 10px;
      text-align: center;
      color: #646464 !important;
      font-weight: 400;
      font-size: 14px;

      .text-container {
        margin: auto;
      }
    }

    .error {
      position: absolute;
      z-index: 5;
      color: red;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
