<template>
  <div class="main__content">
    <div class="main__content-headline">
      <h5>Included in delivery</h5>
    </div>
    <div class="main__content-in">
      <draggable
        v-model="build.included_in_delivery"
        :sort="true"
        handle=".btn-burger"
        tag="div"
      >
        <div v-for="(item, index) in build.included_in_delivery" :key="item.id">
          <image-and-text
            v-if="item.type === 'image_and_text'"
            v-model="build.included_in_delivery[index]"
            @onRemove="removeSection(item)"
          />
          <users-manual
            v-if="item.type === 'users_manual'"
            v-model="build.included_in_delivery[index]"
            :model="model"
            @onRemove="removeSection(item)"
          />
          <accessories-box
            v-if="item.type === 'accessories_box'"
            v-model="build.included_in_delivery[index]"
            @onRemove="removeSection(item)"
          />
          <security-strap
            v-if="item.type === 'securing_strap'"
            v-model="build.included_in_delivery[index]"
            @onRemove="removeSection(item)"
          />
        </div>
      </draggable>

      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Add block:</span>
        </div>

        <div class="main__content-item_r">
          <div class="inp-group__dropdown inp-group__xl">
            <v-select
              v-model="selected"
              :clearable="false"
              :options="options"
              :searchable="false"
              label="title"
              @input="addSection"
            >
              <template #option="{ title, disabled }">
                <span :class="{ disabled: disabled }">{{ title }}</span>
              </template>
            </v-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import ImageAndText from "@/components/partials/builds/included-in-delivery/ImageAndText";
import AccessoriesBox from "@/components/partials/builds/included-in-delivery/AccessoriesBox";
import SecurityStrap from "@/components/partials/builds/included-in-delivery/SecurityStrap";
import UsersManual from "@/components/partials/builds/included-in-delivery/UsersManual";
import draggable from "vuedraggable";
import IncludeInDelivery from "@/store/models/IncludeInDelivery";

export default {
  name: "IncludedInDelivery",
  props: ["model"],
  components: {
    vSelect,
    "image-and-text": ImageAndText,
    "accessories-box": AccessoriesBox,
    "security-strap": SecurityStrap,
    "users-manual": UsersManual,
    draggable,
  },
  computed: {
    build() {
      return this.$store.state.build.build;
    },
    options() {
      let items = [
        {
          id: "image_and_text",
          title: "Image and Text",
          disabled: false,
        },
      ];
      if (
        this.build.included_in_delivery.filter(item => {
          return item.type === "accessories_box";
        }).length === 0
      ) {
        items.push({
          id: "accessories_box",
          title: "Accessories box",
          disabled: false,
        });
      } else {
        items.push({
          id: "accessories_box",
          title: "Accessories box",
          disabled: true,
        });
      }
      if (
        this.build.included_in_delivery.filter(item => {
          return item.type === "users_manual";
        }).length === 0
      ) {
        items.push({
          id: "users_manual",
          title: "Users manual",
          disabled: false,
        });
      } else {
        items.push({
          id: "users_manual",
          title: "Users manual",
          disabled: true,
        });
      }
      if (
        this.build.included_in_delivery.filter(item => {
          return item.type === "securing_strap";
        }).length === 0
      ) {
        items.push({
          id: "securing_strap",
          title: "Securing strap",
          disabled: false,
        });
      } else {
        items.push({
          id: "securing_strap",
          title: "Securing strap",
          disabled: true,
        });
      }
      return items;
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    removeSection(item) {
      if (item.required !== true) {
        this.$store.commit("build/filterIncludeInDelivery", item);
      } else {
        this.$root.$emit("modalShow", {
          text: "This section is required",
          type: "error",
        });
      }
    },
    addSection(event) {
      if (!event.disabled) {
        let newSection = new IncludeInDelivery();
        newSection.type = event.id;
        let max = 0;
        this.build.included_in_delivery.map(item => {
          if (item.id > max) {
            max = item.id;
          }
        });
        newSection.id = max + 1;
        this.build.included_in_delivery.push(newSection);
        this.selected = null;
      }
    },
  },
};
</script>
