export function getFileCategory(fileOrURL) {
  let videoExtensions = ["mp4", "avi", "mkv", "mov", "wmv", "flv", "webm", "m4v", "mpg", "mpeg", "ogv"];
  let imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "tiff", "svg", "heic"];
  let documentExtensions = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt", "odt", "ods", "odp", "rtf", "zip", "rar"];

  let videoMimeTypes = [
    "video/mp4",
    "video/x-msvideo",
    "video/x-matroska",
    "video/quicktime",
    "video/x-ms-wmv",
    "video/x-flv",
    "video/webm",
    "video/x-m4v",
    "video/mpeg",
    "video/ogg",
  ];

  let imageMimeTypes = [
    "image/jpeg",
    "image/png",
    "image/gif",
    "image/bmp",
    "image/webp",
    "image/tiff",
    "image/heic",
    "image/svg+xml",
  ];

  let documentMimeTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "text/plain",
    "application/vnd.oasis.opendocument.text",
    "application/vnd.oasis.opendocument.spreadsheet",
    "application/vnd.oasis.opendocument.presentation",
    "application/rtf",
    "application/zip",
    "application/rar",
  ];

  function getFileCategoryFromMimeType(mimeType) {
    if (videoMimeTypes.includes(mimeType)) {
      return "video";
    } else if (imageMimeTypes.includes(mimeType)) {
      return "photo";
    } else if (documentMimeTypes.includes(mimeType)) {
      return "document";
    } else {
      return null;
    }
  }

  function getFileCategoryFromFilename(filename) {
    let extension = filename.split(".").pop().toLowerCase();
    if (videoExtensions.includes(extension)) {
      return "video";
    } else if (imageExtensions.includes(extension)) {
      return "photo";
    } else if (documentExtensions.includes(extension)) {
      return "document";
    } else {
      return "unknown";
    }
  }

  function getFileCategoryFromUrl(url) {
    let filename = url.split("/").pop().split("?")[0];
    return getFileCategoryFromFilename(filename);
  }

  if (fileOrURL instanceof File) {
    let mimeType = fileOrURL.type;
    let category = getFileCategoryFromMimeType(mimeType);
    if (category) {
      return category;
    }

    return getFileCategoryFromFilename(fileOrURL.name);
  } else if (typeof fileOrURL === "string") {
    return getFileCategoryFromUrl(fileOrURL);
  } else {
    return "unknown";
  }
}

export function createVideoURL(file) {
  if (!(file instanceof File)) {
    throw new Error("Аргумент должен быть объектом File");
  }
  // Создаем ссылку на объект файла
  return URL.createObjectURL(file);
}
