import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "../../helpers/parseError";
import { OrderProduct } from "../../models/OrderProduct";
import OrderUser from "../../models/OrderUser";
import { ALLOW_TYPE_DELIVERIES, DELIVERY_TYPE } from "./address";
import DeliveryAddress from "../../models/DeliveryAddress";
import InvoiceAddress from "../../models/InvoiceAddress";
import hash from "object-hash";
import _ from "lodash";

// initial state
const state = {
  id: null,
  phone: null,
  invoice_number: null,
  user: new OrderUser(),
  failed: false,
  mode_edit: false,
  page_is_created: false,
  last_hash: "",
  is_pickup: false,
  data: {
    user_id: null,
    products: []
  },
  order_status: null
};

const defaultState = Object.assign({}, state);

// getters
const getters = {
  products: state => {
    return state.data.products;
  },
  getTotalQuantity: state => {
    let quantity = 0;
    state.data.products.map(product => (quantity += product.quantity));

    return quantity;
  },
  getDataForRequest: (state, getters, rootState, rootGetters) => {
    let data = state.data;
    let deliveryAddress = rootState.orderAddress.delivery_address;
    let invoiceAddress = rootState.orderAddress.invoice_address;

    if (deliveryAddress instanceof DeliveryAddress) {
      data.delivery_address_id = deliveryAddress.id || null;
    }

    if (rootGetters["orderAddress/isSameAddresses"]) {
      delete data.invoice_address_id;
    } else if (invoiceAddress instanceof InvoiceAddress) {
      data.invoice_address_id = invoiceAddress.id || null;
    }

    let discountValue = rootState.orderPrice.discount_value;
    let discountType = rootState.orderPrice.discount_type;
    if (discountValue <= 0) {
      discountValue = null;
      discountType = null;
    }

    data.discount_type = discountType;
    data.discount_value = discountValue;

    return data;
  },
  canEdit: state => {
    return state.mode_edit || state.page_is_created;
  },
  canProductEdit: (state, rootState, getters, rootGetters) => {
    return (
      state.page_is_created ||
      (rootGetters["orderItem/canEdit"] &&
        !rootGetters["orderPrice/wasInvoice"])
    );
  },
  getHash: state => s => {
    let data = Object.assign({}, s || state);
    delete data.mode_edit;
    delete data.last_hash;
    delete data.page_is_created;

    return hash(data);
  },
  wasEdited: (state, rootState, getters, rootGetters) => {
    if (rootGetters["orderPrice/wasEdited"]) {
      return true;
    }

    let lastHash = rootGetters["orderItem/getHash"](state);

    if (state.page_is_created) {
      return lastHash !== rootGetters["orderItem/getHash"](defaultState);
    }

    if (state.last_hash === "") {
      return false;
    }

    return lastHash !== state.last_hash;
  },
  checkoutPhone: state => {
    return state.phone;
  },
  isPickup: state => {
    return state.is_pickup;
  },
  orderId: state => state.id
};

// actions
const actions = {
  setLastHash({ state, getters }) {
    state.last_hash = getters["getHash"](state);
  },
  setModeEdit({ commit }, value) {
    commit("set_mode_edit", value);
  },
  toggleModeEdit({ commit, state }) {
    commit("set_mode_edit", !state.mode_edit);
  },
  createOrder({ state, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}orders/manual`;

      axios
        .post(url, getters["getDataForRequest"])
        .then(response => {
          resolve(response);
          dispatch("loadOrder", state.id)
            .then(() => resolve(response))
            .catch(err => reject(parseError(err)));
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  updateOrder({ state, dispatch, getters }) {
    return new Promise((resolve, reject) => {
      let url = `${API_ENDPOINT}orders/manual/${state.id}`;

      axios
        .post(url, getters["getDataForRequest"])
        .then(response => {
          dispatch("loadOrder", state.id)
            .then(() => resolve(response))
            .catch(err => reject(parseError(err)));
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  loadOrder({ commit, state, dispatch }, id) {
    state.last_hash = "";
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_ENDPOINT}orders/${id}`)
        .then(response => {
          let root = { root: true };
          commit("set_id", response.data.id);
          commit("set_phone", response.data.phone);
          commit("set_is_pickup", response.data.is_pickup);
          commit("set_invoice_number", response.data.invoice_number);
          commit("set_user", OrderUser.fromResponse(response.data.user));
          dispatch("orderStatus/parseFromOrderResponse", response, root);
          commit("set_failed", response.data.failed);
          commit("set_order_status", response.data.order_status);

          dispatch("orderAddress/loadUserAddresses", {}, root).then(() => {
            ALLOW_TYPE_DELIVERIES.map(type => {
              let data = {
                type,
                address_id:
                  response.data.is_pickup && type === DELIVERY_TYPE
                    ? "pickup"
                    : response.data[`${type}_address_id`]
              };
              dispatch("orderAddress/changeAddress", data, root);
            });
          });

          commit("clear_products");
          response.data["basket"]["items"].map(product =>
            commit(
              "add_product",
              OrderProduct.parseFromResponse(product, response["data"])
            )
          );

          dispatch("orderPrice/parseFromOrderResponse", response, root);
          dispatch("orderPrice/loadInvoices", null, root);

          dispatch("setLastHash");

          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  addProduct({ commit }, order_product) {
    commit("add_product", order_product);
  },
  loadUser({ commit, dispatch }, user_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(API_ENDPOINT + `users/${user_id}`)
        .then(response => {
          commit("set_user", OrderUser.fromResponse(response.data));
          dispatch("orderAddress/loadUserAddresses", {}, { root: true });
          dispatch("orderAddress/clearAddresses", {}, { root: true });

          resolve(response);
        })
        .catch(err => {
          reject(parseError(err));
        });
    });
  },
  createUser({ state }) {
    return axios.post(`${API_ENDPOINT}users/manual`, state.user);
  },
  updateUser({ state }) {
    let url = `${API_ENDPOINT}users/manual/${state.user.id}`;

    return axios.post(url, state.user);
  },
  clear({ commit, dispatch }) {
    dispatch("orderAddress/clearAddresses", {}, { root: true });
    dispatch("orderPrice/setDefault", {}, { root: true });
    dispatch("orderPrice/clearInvoices", {}, { root: true });
    commit("clear");
    commit("orderFeed/clear_comments", null, { root: true });
  },
  resetUser({ commit, dispatch }) {
    commit("reset_user");
    dispatch("orderAddress/clearAddresses", {}, { root: true });
  },
  checkFrameNumberAvailability(context, order_product) {
    let frameNumber;
    if (order_product.frame_number) {
      frameNumber = order_product.frame_number.toString();
    } else {
      frameNumber = null;
    }
    let data = {
      product_id: order_product.build.id,
      frame_number: frameNumber,
      order_id: order_product.order_id
    };

    let errText = `Frame number ${frameNumber} already reserved.`;
    let productNumbers = state.data.products.filter(product => {
      return product.frame_number && product.id !== order_product.product_id
        ? product.frame_number.toString() === frameNumber
        : false;
    });

    return new Promise((resolve, reject) => {
      if (productNumbers.length > 1) {
        reject(errText);
      }

      if (!frameNumber) {
        resolve(true);
      } else {
        axios
          .post(`${API_ENDPOINT}products/frame-check`, data)
          .then(response => {
            if (!response.data.data["available"]) {
              reject(errText);
            } else {
              resolve(response);
            }
          })
          .catch(err => reject(err));
      }
    });
  },
  hasSameBike(context, order_product) {
    let products = state.data.products.filter(product => {
      return (
        order_product.product_id === product.product_id &&
        order_product.is_testing === product.is_testing
      );
    });
    return products.length > 1;
  },
  mergeBikes(context, data) {
    _.map(state.data.products, function(product, index) {
      if (
        data.order_product.product_id === product.product_id &&
        data.order_product.is_testing === product.is_testing &&
        data.index !== index
      ) {
        product.quantity += data.order_product.quantity;
        console.log(product.quantity);
      }
      return product;
    });

    context.commit("remove_product", data.index);
  }
};

// mutations
const mutations = {
  updateProductPrices(state, items) {
    state.data.products = state.data.products.map(product => {
      for (let item of items) {
        if (product.id === item.id && product.build.id === item.build_id) {
          product.price = item.sub_total;
          product.singlePrice = item.single_price;
        }
      }
      return product;
    });
  },
  set_mode_edit(state, value) {
    state.mode_edit = value;
  },
  set_id(state, id) {
    state.id = id;
  },
  set_phone(state, phone) {
    state.phone = phone;
  },
  set_is_pickup(state, isPickup) {
    state.is_pickup = isPickup;
  },
  set_invoice_number(state, invoice_number) {
    state.invoice_number = invoice_number;
  },
  add_product(state, order_product) {
    for (let index in state.data.products) {
      if (
        // eslint-disable-next-line
        state.data.products.hasOwnProperty(index) &&
        state.data.products[index].build.id === order_product.build.id &&
        !state.data.products[index].is_testing
      ) {
        state.data.products[index].quantity += 1;
        return false;
      }
    }

    state.data.products.push(order_product);
  },
  remove_product(state, index) {
    state.data.products.splice(index, 1);
  },
  clear_products(state) {
    state.data.products = [];
  },
  set_user(state, user) {
    state.user = user;
    state.data.user_id = user.id || null;
  },
  reset_user(state) {
    state.user = new OrderUser();
    state.data.user_id = null;
  },
  set_failed(state, failed) {
    state.failed = failed;
  },
  set_order_status(state, order_status) {
    state.order_status = order_status;
  },
  clear(state) {
    state.id = null;
    state.invoice_number = null;
    state.user = new OrderUser();
    state.failed = false;
    state.data = {
      user_id: null,
      products: []
    };
    state.order_status = null;
  },
  set_page_is_created(state, value) {
    state.page_is_created = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
