<template>
  <layout>
    <template v-slot:header-title>
      <span>Creating page</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Page info</h5>
          </div>

          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="`1-${language.title}`"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div class="main__content tab-pane">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="`2-${language.title}`"
                    class="language-tabs"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.page[language.formNamePrefix + 'title']
                            "
                            :name="language.formNamePrefix + 'title'"
                            title="Title"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Page content:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group">
                          <quill-editor
                            v-model="
                              $data.page[language.formNamePrefix + 'content']
                            "
                            :options="quillOptions"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Category:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div
                        v-if="cats"
                        class="inp-group__dropdown inp-group__xl"
                      >
                        <v-select
                          v-model="currentPageCategory"
                          :options="cats"
                          label="title"
                          placeholder="Select Page"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Activity:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-1"
                          v-model="page.is_active"
                          type="checkbox"
                        />
                        <label for="checkbox-1"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Open in new tab:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-2"
                          v-model="page.new_tab"
                          type="checkbox"
                        />
                        <label for="checkbox-2"></label>
                      </div>
                    </div>
                  </div>

                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="`3-${language.title}`"
                    class="language-tabs"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>External link:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.page[`${language.formNamePrefix}outer_link`]
                            "
                            title="Outer link"
                            type="url"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Upload file:</span>
                      </div>

                      <div class="main__content-item_r">
                        <media-uploader
                          :key="`${language.formNamePrefix}file`"
                          :auto-processing="true"
                          :field-name="dropzoneSettings.paramName"
                          :multiple="false"
                          :media="page[`${language.formNamePrefix}outer_link`]"
                          :processing-u-r-l="dropzoneSettings.url"
                          :request-headers="dropzoneSettings.headers"
                          footer-text="Drag&drop or <u>click</u> here to upload image (jpg 1920x1080 up to 15MB)"
                          type="image"
                          @onRemove="removeFileLink(language.formNamePrefix)"
                          @onSuccessResponse="fileUploaded($event, language.formNamePrefix)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save()">
              <span><i>Save page</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import vSelect from "vue-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { API_ENDPOINT } from "@/utils/api";
import MediaUploader from "@/components/inputs/MediaUploader.vue";

export default {
  name: "CRMPageCreate",
  components: {
    MediaUploader,
    layout: CRMLayout,
    vSelect
  },
  data() {
    return {
      quillOptions: {
        modules: {
          toolbar: [["bold", "italic"], ["link"], [{ align: [] }]],
          // 'nbsp-changer': true
        },
        placeholder: "Some text...",
        theme: "snow",
      },
      cats: [],
      currentPageCategory: {
        id: -1,
        title: "Not selected",
      },
      page: {
        slug: null,
        is_active: false,
        title: null,
        es_title: null,
        en_title: null,
        de_title: null,
        fr_title: null,
        ru_title: null,
        content: null,
        es_content: null,
        de_content: null,
        fr_content: null,
        ru_content: null,
        category_id: 0,
        new_tab: false,
        outer_link: null,
        es_outer_link: null,
        de_outer_link: null,
        fr_outer_link: null,
        ru_outer_link: null,
      },
      editor: ClassicEditor,
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: "",
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_",
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_",
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_",
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_",
        },
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: "",
      },
    };
  },
  methods: {
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    save() {
      let ctx = this;
      this.page.category_id = this.currentPageCategory.id;
      this.$store
        .dispatch("store_static_page_request", this.page)
        .then(() => {
          ctx.$root.$emit("modalShow", {
            text: "Page successfully created",
            type: "info",
          });
          ctx.$router.push(`/pages`);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    getCat() {
      let ctx = this;
      this.$store
        .dispatch("get_page_categories_request")
        .then(response => {
          ctx.cats = response.data.map(category => {
            return {
              id: category.id,
              title: category.title,
            };
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    fileUploaded(response, prefix) {
      this.$data.page[`${prefix}outer_link`] = `${response.data.path}`;
      this.$store.state.status = "success";
    },
    removeFileLink(prefix) {
      this.$data.page[`${prefix}outer_link`] = null;
    },
  },
  mounted() {
    this.getCat();
  },
  computed: {
    dropzoneSettings() {
      return {
        url: API_ENDPOINT + `upload-page-file`,
        paramName: "file",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      };
    },
  },
};
</script>

<style scoped>
.language-tabs {
  margin-bottom: 15px;
}

.main__content-item_r {
  max-width: 100%;
}

.ck-editor__editable_inline {
  min-height: 400px;
}
</style>
