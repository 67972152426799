import { API_ENDPOINT } from "@/utils/api";
import axios from "axios";
import parseError from "../helpers/parseError";

const state = {
  status: "",
  code: 200,
  token: localStorage.getItem("token") || "",
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {},
  meta: [],
  userTypes: [],
  partnerTypes: [],
  authors: [],
  customers: {},
  pages: {},
  customer: {},
};

const mutations = {
  auth_request(state) {
    state.status = "loading";
  },
  auth_success(state, data) {
    state.status = "success";
    if (data.token) {
      state.token = data.token;
    }
    state.user = data.profile;
  },
  auth_error(state) {
    state.status = "error";
  },
  logout_request(state) {
    state.status = "loading";
  },
  logout_success(state) {
    state.status = "success";
    state.token = "";
    state.user = {};
  },
  logout_error(state) {
    state.status = "error";
  },
  get_meta_request(state) {
    state.status = "loading";
  },
  get_meta_success(state, meta) {
    state.status = "success";
    state.meta = meta;
  },
  get_meta_error(state) {
    state.status = "error";
  },
  get_user_type_request(state) {
    state.status = "loading";
  },
  get_partner_types_request(state) {
    state.status = "loading";
  },
  get_user_type_success(state, types) {
    state.status = "success";
    state.userTypes = types;
  },
  get_partner_types_success(state, types) {
    state.status = "success";
    state.partnerTypes = types;
  },
  get_user_type_error(state) {
    state.status = "error";
  },
  get_partner_types_error() {
    state.status = "error";
  },
  get_authors_request(state) {
    state.status = "loading";
  },
  get_authors_success(state, authors) {
    state.status = "success";
    state.authors = authors;
  },
  get_authors_error(state) {
    state.status = "error";
  },
  get_customers_request(state) {
    state.status = "loading";
  },
  get_customers_success(state, data) {
    state.status = "success";
    state.customers = data;
  },
  get_customers_error(state) {
    state.status = "error";
  },
  get_pages_request(state) {
    state.status = "loading";
  },
  get_pages_success(state, data) {
    state.status = "success";
    state.pages = data;
  },
  get_pages_error(state) {
    state.status = "error";
  },
  get_customer_success(state, data) {
    state.status = "success";
    state.customer = data;
  },
  get_customer_errors(state) {
    state.status = "error";
  },
  delete_user_request(state) {
    state.status = "loading";
  },
  delete_user_error(state) {
    state.status = "error";
  },
  delete_user_success(state) {
    state.status = "success";
  },
  create_customer_request(state) {
    state.status = "loading";
  },
  create_customer_success(state) {
    state.status = "success";
  },
  create_customer_error(state) {
    state.status = "error";
  },
  create_customer_b2b_request(state) {
    state.status = "loading";
  },
  create_customer_b2b_success(state) {
    state.status = "success";
  },
  create_customer_b2b_error(state) {
    state.status = "error";
  },
  update_customer_request(state) {
    state.status = "loading";
  },
  update_customer_success(state) {
    state.status = "success";
  },
  update_customer_error(state) {
    state.status = "error";
  },
  update_customer_type_request(state) {
    state.status = "loading";
  },
  update_customer_type_success(state) {
    state.status = "success";
  },
  update_customer_type_error(state) {
    state.status = "error";
  },
  update_customer_b2b_request(state) {
    state.status = "loading";
  },
  update_customer_b2b_success(state) {
    state.status = "success";
  },
  update_customer_b2b_error(state) {
    state.status = "error";
  },
  reset_password_request(state) {
    state.status = "loading";
  },
  reset_password_success(state) {
    state.status = "success";
  },
  reset_password_error(state) {
    state.status = "error";
  },
  update_user_avatar_request(state) {
    state.status = "loading";
  },
  update_user_avatar_error(state) {
    state.status = "error";
  },
  update_user_avatar_success(state) {
    state.status = "success";
  },
  create_post_request(state) {
    state.status = "loading";
  },
  create_post_error(state) {
    state.status = "error";
  },
  create_post_success(state) {
    state.status = "success";
  },
  create_support_entry_request(state) {
    state.status = "loading";
  },
  create_support_entry_error(state) {
    state.status = "error";
  },
  create_support_entry_success(state) {
    state.status = "success";
  },
  update_post_request(state) {
    state.status = "loading";
  },
  update_post_error(state) {
    state.status = "error";
  },
  update_post_success(state) {
    state.status = "success";
  },
  get_posts_request(state) {
    state.status = "loading";
  },
  get_posts_error(state) {
    state.status = "error";
  },
  get_posts_success(state) {
    state.status = "success";
  },
  get_post_request(state) {
    state.status = "loading";
  },
  get_post_error(state) {
    state.status = "error";
  },
  get_post_success(state) {
    state.status = "success";
  },
  get_support_entry_request(state) {
    state.status = "loading";
  },
  get_support_entry_error(state) {
    state.status = "error";
  },
  get_support_entry_success(state) {
    state.status = "success";
  },
  get_support_entries_request(state) {
    state.status = "loading";
  },
  get_support_entries_error(state) {
    state.status = "error";
  },
  get_support_entries_success(state) {
    state.status = "success";
  },
  switch_post_activity_request(state) {
    state.status = "loading";
  },
  switch_post_activity_error(state) {
    state.status = "error";
  },
  switch_post_activity_success(state) {
    state.status = "success";
  },
  switch_vacancy_activity_request(state) {
    state.status = "loading";
  },
  switch_vacancy_activity_error(state) {
    state.status = "error";
  },
  switch_vacancy_activity_success(state) {
    state.status = "success";
  },
  switch_support_entry_activity_request(state) {
    state.status = "loading";
  },
  switch_support_entry_activity_error(state) {
    state.status = "error";
  },
  switch_support_entry_activity_success(state) {
    state.status = "success";
  },
  sort_news_request(state) {
    state.status = "loading";
  },
  sort_news_error(state) {
    state.status = "error";
  },
  sort_news_success(state) {
    state.status = "success";
  },
  get_cities_request(state) {
    state.status = "loading";
  },
  get_cities_success(state) {
    state.status = "success";
  },
  get_cities_error(state) {
    state.status = "error";
  },
  get_countries_request(state) {
    state.status = "loading";
  },
  get_countries_success(state) {
    state.status = "success";
  },
  get_countries_error(state) {
    state.status = "error";
  },
  get_part_categories_request(state) {
    state.status = "loading";
  },
  get_part_categories_error(state) {
    state.status = "error";
  },
  get_part_categories_success(state) {
    state.status = "success";
  },
  get_part_brands_request(state) {
    state.status = "loading";
  },
  get_part_brands_error(state) {
    state.status = "error";
  },
  get_part_brands_success(state) {
    state.status = "success";
  },
  store_product_part_category_request(state) {
    state.status = "loading";
  },
  store_product_part_category_error(state) {
    state.status = "error";
  },
  store_product_part_category_success(state) {
    state.status = "success";
  },
  store_product_part_brand_request(state) {
    state.status = "loading";
  },
  store_product_part_brand_error(state) {
    state.status = "error";
  },
  store_product_part_brand_success(state) {
    state.status = "success";
  },
  get_part_category_request(state) {
    state.status = "loading";
  },
  get_part_category_error(state) {
    state.status = "error";
  },
  get_part_category_success(state) {
    state.status = "success";
  },
  get_part_brand_request(state) {
    state.status = "loading";
  },
  get_part_brand_error(state) {
    state.status = "error";
  },
  get_part_brand_success(state) {
    state.status = "success";
  },
  update_product_part_category_request(state) {
    state.status = "loading";
  },
  update_product_part_category_error(state) {
    state.status = "error";
  },
  update_product_part_category_success(state) {
    state.status = "success";
  },
  update_product_part_brand_request(state) {
    state.status = "loading";
  },
  update_product_part_brand_error(state) {
    state.status = "error";
  },
  update_product_part_brand_success(state) {
    state.status = "success";
  },
  get_part_categories_with_pagination_request(state) {
    state.status = "loading";
  },
  get_part_categories_with_pagination_error(state) {
    state.status = "error";
  },
  get_part_categories_with_pagination_success(state) {
    state.status = "success";
  },
  get_import_parts_template_request(state) {
    state.status = "loading";
  },
  get_import_parts_template_error(state) {
    state.status = "error";
  },
  get_import_parts_template_success(state) {
    state.status = "success";
  },
  get_template_parts_columns_request(state) {
    state.status = "loading";
  },
  get_template_parts_columns_error(state) {
    state.status = "error";
  },
  get_template_parts_columns_success(state) {
    state.status = "success";
  },
  upload_import_part_file_request(state) {
    state.status = "loading";
  },
  upload_import_part_file_error(state) {
    state.status = "error";
  },
  upload_import_part_file_success(state) {
    state.status = "success";
  },
  confirm_upload_import_part_file_request(state) {
    state.status = "loading";
  },
  confirm_upload_import_part_file_error(state) {
    state.status = "error";
  },
  confirm_upload_import_part_file_success(state) {
    state.status = "success";
  },
  get_import_parts_history_request(state) {
    state.status = "loading";
  },
  get_import_parts_history_error(state) {
    state.status = "error";
  },
  get_import_parts_history_success(state) {
    state.status = "success";
  },
  delete_import_part_file_request(state) {
    state.status = "loading";
  },
  delete_import_part_file_error(state) {
    state.status = "error";
  },
  delete_import_part_file_success(state) {
    state.status = "success";
  },
  get_product_items_request(state) {
    state.status = "loading";
  },
  sort_product_items_request(state) {
    state.status = "loading";
  },
  sort_product_items_success(state) {
    state.status = "success";
  },
  sort_product_items_error(state) {
    state.status = "error";
  },
  get_product_items_error(state) {
    state.status = "error";
  },
  get_product_items_success(state) {
    state.status = "success";
  },
  get_part_request(state) {
    state.status = "loading";
  },
  get_part_error(state) {
    state.status = "error";
  },
  get_part_success(state) {
    state.status = "success";
  },
  get_product_categories_request(state) {
    state.status = "loading";
  },
  get_product_categories_error(state) {
    state.status = "error";
  },
  get_product_categories_success(state) {
    state.status = "success";
  },
  get_product_models_request(state) {
    state.status = "loading";
  },
  get_product_models_error(state) {
    state.status = "error";
  },
  get_product_models_success(state) {
    state.status = "success";
  },
  get_product_builds_request(state) {
    state.status = "loading";
  },
  get_product_builds_error(state) {
    state.status = "error";
  },
  get_product_builds_success(state) {
    state.status = "success";
  },
  get_product_colors_request(state) {
    state.status = "loading";
  },
  get_product_colors_error(state) {
    state.status = "error";
  },
  get_product_colors_success(state) {
    state.status = "success";
  },
  save_product_request(state) {
    state.status = "loading";
  },
  save_product_error(state) {
    state.status = "error";
  },
  save_product_success(state) {
    state.status = "success";
  },
  get_product_request(state) {
    state.status = "loading";
  },
  get_product_error(state) {
    state.status = "error";
  },
  get_product_success(state) {
    state.status = "success";
  },

  save_settings_request(state) {
    state.status = "loading";
  },
  save_settings_error(state) {
    state.status = "error";
  },
  save_settings_success(state) {
    state.status = "success";
  },
  get_settings_request(state) {
    state.status = "loading";
  },
  get_settings_error(state) {
    state.status = "error";
  },
  get_settings_success(state) {
    state.status = "success";
  },
  update_pages_request(state) {
    state.status = "loading";
  },
  update_pages_error(state) {
    state.status = "error";
  },
  update_pages_success(state) {
    state.status = "success";
  },
  save_pages_request(state) {
    state.status = "loading";
  },
  save_pages_error(state) {
    state.status = "error";
  },
  save_pages_success(state) {
    state.status = "success";
  },

  delete_page_request(state) {
    state.status = "loading";
  },
  delete_page_error(state) {
    state.status = "error";
  },
  delete_page_success(state) {
    state.status = "success";
  },

  store_product_color_request(state) {
    state.status = "loading";
  },
  store_product_color_error(state) {
    state.status = "error";
  },
  store_product_color_success(state) {
    state.status = "success";
  },
  add_comment_for_order_error(state) {
    state.status = "error";
  },
  add_comment_for_order_success(state) {
    state.status = "success";
  },
  store_product_model_request(state) {
    state.status = "loading";
  },
  store_product_model_error(state) {
    state.status = "error";
  },
  store_product_model_success(state) {
    state.status = "success";
  },
  update_product_model_request(state) {
    state.status = "loading";
  },
  update_product_model_error(state) {
    state.status = "error";
  },
  update_product_model_success(state) {
    state.status = "success";
  },
  delete_product_request(state) {
    state.status = "loading";
  },
  delete_product_error(state) {
    state.status = "error";
  },
  delete_product_success(state) {
    state.status = "success";
  },
  clone_product_request(state) {
    state.status = "loading";
  },
  clone_product_error(state) {
    state.status = "error";
  },
  clone_product_success(state) {
    state.status = "success";
  },
  get_orders_request(state) {
    state.status = "loading";
  },
  get_orders_error(state) {
    state.status = "error";
  },
  get_orders_success(state) {
    state.status = "success";
  },
  get_count_orders_success(state) {
    state.status = "success";
  },
  get_count_orders_error(state) {
    state.status = "error";
  },
  get_count_orders_request(state) {
    state.status = "loading";
  },
  get_order_request(state) {
    state.status = "loading";
  },
  get_order_error(state) {
    state.status = "error";
  },
  get_order_success(state) {
    state.status = "success";
  },
  get_payment_methods_request(state) {
    state.status = "loading";
  },
  get_payment_methods_error(state) {
    state.status = "error";
  },
  get_payment_methods_success(state) {
    state.status = "success";
  },
  get_order_statuses_request(state) {
    state.status = "loading";
  },
  get_order_statuses_error(state) {
    state.status = "error";
  },
  get_order_statuses_success(state) {
    state.status = "success";
  },
  download_order_pdf_request(state) {
    state.status = "loading";
  },
  download_order_pdf_error(state) {
    state.status = "error";
  },
  download_order_pdf_success(state) {
    state.status = "success";
  },
  update_order_status_request(state) {
    state.status = "loading";
  },
  update_order_status_error(state) {
    state.status = "error";
  },
  update_order_status_success(state) {
    state.status = "success";
  },
  update_order_user_request(state) {
    state.status = "loading";
  },
  update_order_user_error(state) {
    state.status = "error";
  },
  update_order_user_success(state) {
    state.status = "success";
  },
  update_order_address_request(state) {
    state.status = "loading";
  },
  update_order_address_error(state) {
    state.status = "error";
  },
  update_order_address_success(state) {
    state.status = "success";
  },
  get_customer_orders_request(state) {
    state.status = "loading";
  },
  get_customer_orders_error(state) {
    state.status = "error";
  },
  get_customer_orders_success(state) {
    state.status = "success";
  },
  send_push_notification_request(state) {
    state.status = "loading";
  },
  send_push_notification_error(state) {
    state.status = "error";
  },
  send_push_notification_success(state) {
    state.status = "success";
  },
  sort_support_entry_request(state) {
    state.status = "loading";
  },
  sort_support_entry_error(state) {
    state.status = "error";
  },
  sort_support_entry_success(state) {
    state.status = "success";
  },
  get_support_categories_request(state) {
    state.status = "loading";
  },
  get_support_categories_error(state) {
    state.status = "error";
  },
  get_support_categories_success(state) {
    state.status = "success";
  },
  store_support_category_request(state) {
    state.status = "loading";
  },
  store_support_category_error(state) {
    state.status = "error";
  },
  store_support_category_success(state) {
    state.status = "success";
  },
  update_support_entry_request(state) {
    state.status = "loading";
  },
  update_support_entry_success(state) {
    state.status = "success";
  },
  update_support_entry_error(state) {
    state.status = "error";
  },
  get_support_category_request(state) {
    state.status = "loading";
  },
  get_support_category_success(state) {
    state.status = "success";
  },
  get_support_category_error(state) {
    state.status = "error";
  },
  store_static_page_request(state) {
    state.status = "loading";
  },
  store_static_page_success(state) {
    state.status = "success";
  },
  store_static_page_error(state) {
    state.status = "error";
  },

  get_page_categories_request(state) {
    state.status = "loading";
  },
  get_page_categories_success(state) {
    state.status = "success";
  },
  get_page_categories_error(state) {
    state.status = "error";
  },
  get_dashboard_stat_request(state) {
    state.status = "loading";
  },
  get_dashboard_stat_success(state) {
    state.status = "success";
  },
  get_dashboard_stat_error(state) {
    state.status = "error";
  },
  get_roles_request(state) {
    state.status = "loading";
  },
  get_roles_success(state) {
    state.status = "success";
  },
  get_roles_error(state) {
    state.status = "error";
  },
  get_role_request(state) {
    state.status = "loading";
  },
  get_role_success(state) {
    state.status = "success";
  },
  get_role_error(state) {
    state.status = "error";
  },
  save_role_request(state) {
    state.status = "loading";
  },
  save_role_success(state) {
    state.status = "success";
  },
  save_role_error(state) {
    state.status = "error";
  },
  create_role_request(state) {
    state.status = "loading";
  },
  create_role_success(state) {
    state.status = "success";
  },
  create_role_error(state) {
    state.status = "error";
  },
  get_premissions_request(state) {
    state.status = "loading";
  },
  get_premissions_success(state) {
    state.status = "success";
  },
  get_premissions_error(state) {
    state.status = "error";
  },
  get_all_customers(state) {
    state.status = "loading";
  },
  get_all_customers_success(state) {
    state.status = "success";
  },
  get_all_customers_error(state) {
    state.status = "error";
  },
  store_vacancy_department_request(state) {
    state.status = "loading";
  },
  store_vacancy_department_error(state) {
    state.status = "error";
  },
  store_vacancy_department_success(state) {
    state.status = "success";
  },
  get_vacancy_department_request(state) {
    state.status = "loading";
  },
  get_vacancy_department_error(state) {
    state.status = "error";
  },
  get_vacancy_department_success(state) {
    state.status = "success";
  },
  sort_vacancy_department_request(state) {
    state.status = "loading";
  },
  sort_vacancy_department_error(state) {
    state.status = "error";
  },
  sort_vacancy_department_success(state) {
    state.status = "success";
  },
  get_vacancy_request(state) {
    state.status = "loading";
  },
  get_vacancy_error(state) {
    state.status = "error";
  },
  get_vacancy_success(state) {
    state.status = "success";
  },
  sort_vacancy_request(state) {
    state.status = "loading";
  },
  sort_vacancy_error(state) {
    state.status = "error";
  },
  sort_vacancy_success(state) {
    state.status = "success";
  },
  get_cvs_request(state) {
    state.status = "loading";
  },
  get_cvs_error(state) {
    state.status = "error";
  },
  get_cvs_success(state) {
    state.status = "success";
  },
  get_cv_single_request(state) {
    state.status = "loading";
  },
  get_cv_single_error(state) {
    state.status = "error";
  },
  get_cv_single_success(state) {
    state.status = "success";
  },
  get_vacancy_department_single_request(state) {
    state.status = "loading";
  },
  get_vacancy_department_single_error(state) {
    state.status = "error";
  },
  get_vacancy_department_single_success(state) {
    state.status = "success";
  },
  update_vacancy_department_request(state) {
    state.status = "loading";
  },
  update_vacancy_department_error(state) {
    state.status = "error";
  },
  update_vacancy_department_success(state) {
    state.status = "success";
  },
  delete_department_request(state) {
    state.status = "loading";
  },
  delete_department_error(state) {
    state.status = "error";
  },
  delete_department_success(state) {
    state.status = "success";
  },
  store_vacancy_request(state) {
    state.status = "loading";
  },
  store_vacancy_error(state) {
    state.status = "error";
  },
  store_vacancy_success(state) {
    state.status = "success";
  },
  get_vacancies_request(state) {
    state.status = "loading";
  },
  get_vacancies_error(state) {
    state.status = "error";
  },
  get_vacancies_success(state) {
    state.status = "success";
  },
  update_vacancy_request(state) {
    state.status = "loading";
  },
  update_vacancy_error(state) {
    state.status = "error";
  },
  update_vacancy_success(state) {
    state.status = "success";
  },
  delete_vacancy_request() {
    state.status = "loading";
  },
  delete_vacancy_error() {
    state.status = "error";
  },
  delete_vacancy_success() {
    state.status = "success";
  },
  get_mail_templates_request(state) {
    state.status = "loading";
  },
  get_mail_templates_error(state) {
    state.status = "error";
  },
  get_mail_templates_success(state) {
    state.status = "success";
  },
};
const actions = {
  publicUpload(context, data) {
    return new Promise((resolve, reject) => {
      const url = API_ENDPOINT + "publicUpload";
      axios({
        url,
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  login({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("auth_request");

      let url = API_ENDPOINT + "loginAdmin";

      axios({ url: url, data: user, method: "POST" })
        .then(resp => {
          const token = resp.data.token.access_token;
          const profile = resp.data.data;

          localStorage.setItem("token", token);
          localStorage.setItem("user", JSON.stringify(profile));

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          commit("auth_success", { token, profile });
          resolve(resp);
        })
        .catch(err => {
          commit("auth_error");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          reject(parseError(err));
        });
    });
  },
  get_meta({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_meta_request");
      let url = API_ENDPOINT + "meta";

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_meta_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_meta_error");
          reject(parseError(err));
        });
    });
  },
  logout({ commit }) {
    return new Promise(resolve => {
      commit("logout_request");

      let url = API_ENDPOINT + "logout";

      axios({ url: url, method: "POST" })
        .then(() => {
          commit("logout_success");
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          delete axios.defaults.headers.common["Authorization"];
          resolve();
        })
        .catch(() => {
          commit("logout_error");
          resolve();
        });
    });
  },
  get_user_types({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_user_type_request");
      let url = API_ENDPOINT + "userTypes";

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_user_type_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_user_type_error");
          reject(parseError(err));
        });
    });
  },
  get_partner_types({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_partner_types_request");
      let url = API_ENDPOINT + "partnerTypes";

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_partner_types_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_partner_types_error");
          reject(parseError(err));
        });
    });
  },
  store_product_part_category_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("store_product_part_category_request");
      let url = API_ENDPOINT + "categories";

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("store_product_part_category_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("store_product_part_category_error");
          reject(parseError(err));
        });
    });
  },
  store_product_part_brand_request({ commit }, brand_name) {
    return new Promise((resolve, reject) => {
      commit("store_product_part_brand_request");
      let url = API_ENDPOINT + "brands";

      axios({ url: url, data: { brand_name }, method: "POST" })
        .then(resp => {
          commit("store_product_part_brand_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("store_product_part_brand_error");
          reject(parseError(err));
        });
    });
  },
  store_product_color_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("store_product_color_request");
      let url = API_ENDPOINT + "productColors";

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("store_product_color_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("store_product_color_error");
          reject(parseError(err));
        });
    });
  },
  add_comment_for_order_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("add_comment_for_order_request");
      let url = API_ENDPOINT + "addComment/" + data.get("order_id");

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("add_comment_for_order_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("add_comment_for_order_error");
          reject(parseError(err));
        });
    });
  },
  resend_email_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("add_comment_for_order_request");
      let url = API_ENDPOINT + "resendInvoice/" + data;
      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("add_comment_for_order_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("add_comment_for_order_error");
          reject(parseError(err));
        });
    });
  },
  store_product_model_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("save_product_request");

      let url = API_ENDPOINT + `productModels`;

      axios({
        url: url,
        data: data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("store_product_model_success");
          resolve(resp);
        })
        .catch(err => {
          commit("store_product_model_error");
          reject(parseError(err));
        });
    });
  },
  update_product_model_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_product_model_request");

      let url = API_ENDPOINT + `productModels/${data.id}`;

      axios({
        url: url,
        data: data.data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("update_product_model_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_product_model_error");
          reject(parseError(err));
        });
    });
  },
  update_product_part_category_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_product_part_category_request");
      let url = API_ENDPOINT + "categories/" + data.id;

      axios({ url: url, data: data.data, method: "PUT" })
        .then(resp => {
          commit("update_product_part_category_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("update_product_part_category_error");
          reject(parseError(err));
        });
    });
  },
  update_product_part_brand_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_product_part_brand_request");
      let url = API_ENDPOINT + "brand/" + data.id;

      axios({ url: url, data: { brand_name: data.data }, method: "PUT" })
        .then(resp => {
          commit("update_product_part_brand_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("update_product_part_brand_error");
          reject(parseError(err));
        });
    });
  },
  get_part_categories_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("get_part_categories_request");
      let url;
      // eslint-disable-next-line
      if (data.hasOwnProperty("type") && data.type === "all") {
        url = API_ENDPOINT + `categories`;
      } else if (data.type === "single") {
        url = API_ENDPOINT + `getModelCats/${data.model_id}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_part_categories_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_part_categories_error");
          reject(parseError(err));
        });
    });
  },
  get_part_categories_with_pagination_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_part_categories_with_pagination_request");
      let url = API_ENDPOINT + "categories?withPagination=yes";

      url += `&${options.parameters.join("&")}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_part_categories_with_pagination_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_part_categories_with_pagination_error");
          reject(parseError(err));
        });
    });
  },
  get_part_brands_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_part_brands_request");
      let url = API_ENDPOINT + "brands";

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_part_brands_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_part_brands_error");
          reject(parseError(err));
        });
    });
  },
  get_part_brands_with_pagination_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_part_brands_request");
      let url = API_ENDPOINT + "brands?withPagination=yes";

      url += `&${options.parameters.join("&")}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_part_brands_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_part_brands_error");
          reject(parseError(err));
        });
    });
  },
  get_part_category_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_part_category_request");
      let url = API_ENDPOINT + "categories/" + id;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_part_category_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_part_category_error");
          reject(parseError(err));
        });
    });
  },
  get_part_brand_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_part_brand_request");
      let url = API_ENDPOINT + "brands/" + id;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_part_brand_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_part_brand_error");
          reject(parseError(err));
        });
    });
  },
  get_import_parts_template_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_import_parts_template_request");
      let url = API_ENDPOINT + "downloadTemplate";

      axios({
        url: url,
        method: "GET",
        responseType: "blob",
      })
        .then(resp => {
          commit("get_import_parts_template_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_import_parts_template_error");
          reject(parseError(err));
        });
    });
  },
  get_import_parts_count_template_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_import_parts_template_request");
      let url = API_ENDPOINT + "downloadPriceTemplate";

      axios({
        url: url,
        method: "GET",
        responseType: "blob",
      })
        .then(resp => {
          commit("get_import_parts_template_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_import_parts_template_error");
          reject(parseError(err));
        });
    });
  },
  get_import_parts_history_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_import_parts_history_request");
      let url = API_ENDPOINT + "getPartsHistory";

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({
        url: url,
        method: "GET",
      })
        .then(resp => {
          commit("get_import_parts_history_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_import_parts_history_error");
          reject(parseError(err));
        });
    });
  },
  get_import_parts_count_history_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_import_parts_history_request");
      let url = API_ENDPOINT + "getPriceHistory";

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({
        url: url,
        method: "GET",
      })
        .then(resp => {
          commit("get_import_parts_history_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_import_parts_history_error");
          reject(parseError(err));
        });
    });
  },
  upload_import_part_file_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("upload_import_part_file_request");
      let url = API_ENDPOINT + "uploadParts";

      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then(resp => {
          commit("upload_import_part_file_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("upload_import_part_file_error");
          reject(parseError(err));
        });
    });
  },
  upload_import_part_count_file_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("upload_import_part_file_request");
      let url = API_ENDPOINT + "uploadPrices";

      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then(resp => {
          commit("upload_import_part_file_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("upload_import_part_file_error");
          reject(parseError(err));
        });
    });
  },
  confirm_upload_import_part_file_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("confirm_upload_import_part_file_request");
      let url = API_ENDPOINT + "confirmImport";

      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then(resp => {
          commit("confirm_upload_import_part_file_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("confirm_upload_import_part_file_error");
          reject(parseError(err));
        });
    });
  },
  confirm_upload_import_part_count_file_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("confirm_upload_import_part_file_request");
      let url = API_ENDPOINT + "confirmPriceImport";

      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then(resp => {
          commit("confirm_upload_import_part_file_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("confirm_upload_import_part_file_error");
          reject(parseError(err));
        });
    });
  },
  delete_import_part_file_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("delete_import_part_file_request");
      let url = API_ENDPOINT + "deleteUploadedPart";

      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then(resp => {
          commit("delete_import_part_file_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("delete_import_part_file_error");
          reject(parseError(err));
        });
    });
  },
  delete_import_part_count_file_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("delete_import_part_file_request");
      let url = API_ENDPOINT + "deleteUploadedPrice";

      axios({
        url: url,
        method: "POST",
        data: data,
      })
        .then(resp => {
          commit("delete_import_part_file_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("delete_import_part_file_error");
          reject(parseError(err));
        });
    });
  },
  get_template_parts_columns_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_template_parts_columns_request");
      let url = API_ENDPOINT + "getTemplateColumns";

      axios({
        url: url,
        method: "GET",
      })
        .then(resp => {
          commit("get_template_parts_columns_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_template_parts_columns_error");
          reject(parseError(err));
        });
    });
  },
  get_template_parts_count_columns_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_template_parts_columns_request");
      let url = API_ENDPOINT + "getPriceTemplateColumns";

      axios({
        url: url,
        method: "GET",
      })
        .then(resp => {
          commit("get_template_parts_columns_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_template_parts_columns_error");
          reject(parseError(err));
        });
    });
  },
  get_authors_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_authors_request");
      let url = API_ENDPOINT + "getAuthors";

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_authors_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_authors_error");
          reject(parseError(err));
        });
    });
  },
  get_pages({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_pages_request");
      let url = API_ENDPOINT + "pages";

      if (options.nextPageUrl) {
        url = options.nextPageUrl;
      }

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_pages_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_pages_errors");
          reject(parseError(err));
        });
    });
  },
  get_page({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_pages_request");
      let url = API_ENDPOINT + `pages/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_pages_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_pages_errors");
          reject(parseError(err));
        });
    });
  },
  update_pages_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_pages_request");

      let url = API_ENDPOINT + `pages/${data.id}`;

      axios({ url: url, data: data.data, method: "PUT" })
        .then(resp => {
          commit("update_pages_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_pages_error");
          reject(parseError(err));
        });
    });
  },
  delete_page_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("delete_page_request");

      let url = API_ENDPOINT + `pages/${id}`;

      axios({ url: url, method: "DELETE" })
        .then(resp => {
          commit("delete_page_success");
          resolve(resp);
        })
        .catch(err => {
          commit("delete_page_error");
          reject(parseError(err));
        });
    });
  },
  get_customers({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_customers_request");
      let url = API_ENDPOINT + "users";

      if (options.nextPageUrl) {
        url = options.nextPageUrl;
      }

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_customers_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_customers_errors");
          reject(parseError(err));
        });
    });
  },
  get_customer({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_customers_request");
      let url = API_ENDPOINT + `users/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_customer_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_customer_errors");
          reject(parseError(err));
        });
    });
  },
  create_customer_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("create_customer_request");

      let url = API_ENDPOINT + "userWithType";

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("create_customer_success");
          resolve(resp);
        })
        .catch(err => {
          commit("create_customer_error");
          reject(parseError(err));
        });
    });
  },
  create_customer_b2b_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("create_customer_b2b_request");

      let url = API_ENDPOINT + "users_b2b";

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("create_customer_b2b_success");
          resolve(resp);
        })
        .catch(err => {
          commit("create_customer_b2b_error");
          reject(parseError(err));
        });
    });
  },
  update_customer_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_customer_request");

      let url = API_ENDPOINT + `users/${data.id}`;

      axios({ url: url, data: data.data, method: "PUT" })
        .then(resp => {
          commit("update_customer_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_customer_error");
          reject(parseError(err));
        });
    });
  },
  update_customer_type({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_customer_type_request");

      let url = API_ENDPOINT + `users/${data.id}/update-type`;

      axios({ url: url, data: data.data, method: "PUT" })
        .then(resp => {
          commit("update_customer_type__success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_customer_type__error");
          reject(parseError(err));
        });
    });
  },
  update_customer_b2b_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_customer_b2b_request");

      let url = API_ENDPOINT + `users_b2b/${data.id}`;

      axios({ url: url, data: data.data, method: "PUT" })
        .then(resp => {
          commit("update_customer_b2b_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_customer_b2b_error");
          reject(parseError(err));
        });
    });
  },
  update_user_avatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_user_avatar_request");

      let url = API_ENDPOINT + `updateImg/${data.id}`;

      axios({
        url: url,
        data: data.avatar,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("update_user_avatar_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_user_avatar_error");
          reject(parseError(err));
        });
    });
  },
  save_product_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("save_product_request");

      let url = API_ENDPOINT + `products`;

      axios({
        url: url,
        data: data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("save_product_success");
          resolve(resp);
        })
        .catch(err => {
          commit("save_product_error");
          reject(parseError(err));
        });
    });
  },

  save_settings_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("save_settings_request");

      let url = API_ENDPOINT + `settingUpdate`;

      axios({
        url: url,
        data: data,
        method: "PUT",
      })
        .then(resp => {
          commit("save_settings_success");
          resolve(resp);
        })
        .catch(err => {
          commit("save_settings_error");
          reject(parseError(err));
        });
    });
  },
  update_product_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("save_product_request");

      let url = API_ENDPOINT + `updateProducts/${data.id}`;

      axios({
        url: url,
        data: data.data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("save_product_success");
          resolve(resp);
        })
        .catch(err => {
          commit("save_product_error");
          reject(parseError(err));
        });
    });
  },
  create_post_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("create_post_request");

      let url = API_ENDPOINT + `news`;

      axios({
        url: url,
        data: data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("create_post_success");
          resolve(resp);
        })
        .catch(err => {
          commit("create_post_error");
          reject(parseError(err));
        });
    });
  },
  create_support_entry_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("create_support_entry_request");

      let url = API_ENDPOINT + `supports`;

      axios({
        url: url,
        data: data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("create_support_entry_success");
          resolve(resp);
        })
        .catch(err => {
          commit("create_support_entry_error");
          reject(parseError(err));
        });
    });
  },
  update_post_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_post_request");

      let url = API_ENDPOINT + `news/${data.id}`;

      axios({
        url: url,
        data: data.data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("update_post_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_post_error");
          reject(parseError(err));
        });
    });
  },
  update_support_entry_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_support_entry_request");

      let url = API_ENDPOINT + `supports/${data.id}`;

      axios({
        url: url,
        data: data.data,
        method: "POST",
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("update_support_entry_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_support_entry_error");
          reject(parseError(err));
        });
    });
  },
  get_posts_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_posts_request");
      let url = API_ENDPOINT + options.parameters.currentItemType;

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_posts_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_post_errors");
          reject(parseError(err));
        });
    });
  },
  get_support_entries_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_support_entries_request");
      let url = API_ENDPOINT + "supports";

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_support_entries_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_support_entries_error");
          reject(parseError(err));
        });
    });
  },
  get_post_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_post_request");
      let url = API_ENDPOINT + "news/" + id;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_post_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_post_error");
          reject(parseError(err));
        });
    });
  },
  get_support_entry_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_support_entry_request");
      let url = API_ENDPOINT + "supports/" + id;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_support_entry_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_support_entry_error");
          reject(parseError(err));
        });
    });
  },
  sort_product_items_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("sort_product_items_request");

      let url = API_ENDPOINT;
      url += "products/sort";
      axios({ url: url, method: "POST", data: options })
        .then(resp => {
          commit("sort_product_items_success");
          resolve(resp);
        })
        .catch(err => {
          commit("sort_product_items_error");
          reject(parseError(err));
        });
    });
  },
  get_product_items_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_product_items_request");

      let url = API_ENDPOINT;

      if (options.type === "parts") {
        url += "getParts";
      } else {
        url += "products";
      }
      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_items_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_items_error");
          reject(parseError(err));
        });
    });
  },
  get_cities_request({ commit }, query) {
    return new Promise((resolve, reject) => {
      commit("get_cities_request");
      let url = API_ENDPOINT + "towns?city_name=" + query;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_cities_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_cities_error");
          reject(parseError(err));
        });
    });
  },
  get_product_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_product_request");
      let url = API_ENDPOINT + `products/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_error");
          reject(parseError(err));
        });
    });
  },

  get_settings_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_settings_request");
      let url = API_ENDPOINT + `settingGet`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_error");
          reject(parseError(err));
        });
    });
  },
  get_product_categories_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_product_categories_request");
      let url = API_ENDPOINT + `productCategories`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_categories_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_categories_error");
          reject(parseError(err));
        });
    });
  },
  get_product_models_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_product_models_request");
      let url = API_ENDPOINT + `productModels`;

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_models_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_models_error");
          reject(parseError(err));
        });
    });
  },
  get_product_model_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_product_models_request");
      let url = API_ENDPOINT + `getModelCats/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_models_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_models_error");
          reject(parseError(err));
        });
    });
  },
  clone_product_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("clone_product_request");
      let url = API_ENDPOINT + `cloneProducts/${id}`;

      axios({ url: url, method: "POST" })
        .then(resp => {
          commit("clone_product_success");
          resolve(resp);
        })
        .catch(err => {
          commit("clone_product_error");
          reject(parseError(err));
        });
    });
  },
  get_product_builds_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_product_builds_request");
      let url = API_ENDPOINT + `productBuilds`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_builds_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_builds_error");
          reject(parseError(err));
        });
    });
  },
  get_product_colors_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_product_colors_request");
      let url = API_ENDPOINT + `productColors`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_colors_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_colors_error");
          reject(parseError(err));
        });
    });
  },
  get_part_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_part_request");
      let url = API_ENDPOINT + `getPart/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_part_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_part_error");
          reject(parseError(err));
        });
    });
  },
  get_countries_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_countries_request");
      let url = API_ENDPOINT + "countries";

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_countries_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_countries_error");
          reject(parseError(err));
        });
    });
  },
  switch_post_activity_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("switch_post_activity_request");

      let url = API_ENDPOINT + `${data.currentItemType.code}/${data.id}`;

      axios({
        url: url,
        data: { active: data.active },
        method: "POST",
      })
        .then(resp => {
          commit("switch_post_activity_success");
          resolve(resp);
        })
        .catch(err => {
          commit("switch_post_activity_error");
          reject(parseError(err));
        });
    });
  },
  switch_vacancy_activity_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("switch_vacancy_activity_request");

      let url = API_ENDPOINT + `vacancies/${data.id}/activity`;

      axios({
        url: url,
        data: { active: data.active },
        method: "PATCH",
      })
        .then(resp => {
          commit("switch_vacancy_activity_success");
          resolve(resp);
        })
        .catch(err => {
          commit("switch_vacancy_activity_error");
          reject(parseError(err));
        });
    });
  },
  switch_support_entry_activity_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("switch_support_entry_activity_request");

      let url = API_ENDPOINT + `supports/${data.id}`;

      axios({
        url: url,
        data: { active: data.active },
        method: "POST",
      })
        .then(resp => {
          commit("switch_support_entry_activity_success");
          resolve(resp);
        })
        .catch(err => {
          commit("switch_support_entry_activity_error");
          reject(parseError(err));
        });
    });
  },
  reset_password_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("reset_password_request");

      let url = API_ENDPOINT + `password/create`;

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("reset_password_success");
          resolve(resp);
        })
        .catch(err => {
          commit("reset_password_error");
          reject(parseError(err));
        });
    });
  },
  sort_news_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("sort_news_request");

      let url = API_ENDPOINT + `sortNews`;

      axios({ url: url, data: { sort: data }, method: "POST" })
        .then(resp => {
          commit("sort_news_success");
          resolve(resp);
        })
        .catch(err => {
          commit("sort_news_error");
          reject(parseError(err));
        });
    });
  },
  sort_support_entry_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("sort_support_entry_request");

      let url = API_ENDPOINT + `sortSupCat`;

      axios({ url: url, data: { sort: data }, method: "POST" })
        .then(resp => {
          commit("sort_support_entry_success");
          resolve(resp);
        })
        .catch(err => {
          commit("sort_support_entry_error");
          reject(parseError(err));
        });
    });
  },
  delete_product_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("delete_product_request");

      let url = API_ENDPOINT + `products/${id}`;

      axios({ url: url, method: "DELETE" })
        .then(resp => {
          commit("delete_product_success");
          resolve(resp);
        })
        .catch(err => {
          commit("delete_product_error");
          reject(parseError(err));
        });
    });
  },
  get_orders_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_orders_request");

      let url = API_ENDPOINT + `orders`;

      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_orders_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_orders_error");
          reject(parseError(err));
        });
    });
  },
  get_count_orders_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_count_orders_request");

      let url = API_ENDPOINT + `getPaidCountOrders`;
      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_count_orders_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_count_orders_error");
          reject(parseError(err));
        });
    });
  },
  get_order_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_order_request");

      let url = API_ENDPOINT + `orders/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_order_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_order_error");
          reject(parseError(err));
        });
    });
  },
  update_order_status_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_order_status_request");

      let url = API_ENDPOINT + `orders/${data.order_id}`;

      axios({
        url: url,
        method: "PUT",
        data: {
          status_id: data.status_id,
        },
      })
        .then(resp => {
          commit("update_order_status_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_order_status_error");
          reject(parseError(err));
        });
    });
  },
  get_payment_methods_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_payment_methods_request");

      let url = API_ENDPOINT + `orderPaymentMethods`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_payment_methods_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_payment_methods_error");
          reject(parseError(err));
        });
    });
  },
  get_order_statuses_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_order_statuses_request");

      let url = API_ENDPOINT + `orderStatuses`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_order_statuses_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_order_statuses_error");
          reject(parseError(err));
        });
    });
  },
  download_order_pdf_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("download_order_pdf_request");

      let url = API_ENDPOINT + `ordersPdf/${id}`;

      axios({ url: url, method: "GET", responseType: "arraybuffer" })
        .then(resp => {
          commit("download_order_pdf_success");
          resolve(resp);
        })
        .catch(err => {
          commit("download_order_pdf_error");
          reject(parseError(err));
        });
    });
  },
  update_order_user_request({ commit }, user) {
    return new Promise((resolve, reject) => {
      commit("update_order_user_request");

      let url = API_ENDPOINT + `updateUserFromOrder/${user.id}`;

      delete user.id;

      axios({
        url: url,
        data: user,
        method: "PUT",
      })
        .then(resp => {
          commit("update_order_user_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_order_user_error");
          reject(parseError(err));
        });
    });
  },
  update_order_address_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_order_address_request");

      let url = API_ENDPOINT + `OrderAddressUpd/${data.address.order_id}`;

      axios({
        url: url,
        data: data.address,
        method: "PUT",
      })
        .then(resp => {
          commit("update_order_address_success");
          resolve(resp);
        })
        .catch(err => {
          commit("update_order_address_error");
          reject(parseError(err));
        });
    });
  },
  get_customer_orders_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_customer_orders_request");

      let url = API_ENDPOINT + `userOrders/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_customer_orders_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_customer_orders_error");
          reject(parseError(err));
        });
    });
  },
  get_support_categories_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("get_support_categories_request");

      let url = API_ENDPOINT + `supportCategories`;

      if (data.withChildren) {
        url += "?withChildren=yes";
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_support_categories_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_support_categories_error");
          reject(parseError(err));
        });
    });
  },
  get_support_category_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_support_category_request");

      let url = API_ENDPOINT + `supportCategories/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_support_category_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_support_category_error");
          reject(parseError(err));
        });
    });
  },
  store_support_category_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("store_support_category_request");

      let url = API_ENDPOINT + `supportCategories`;

      axios({
        url: url,
        method: "POST",
        data: data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("store_support_category_success");
          resolve(resp);
        })
        .catch(err => {
          commit("store_support_category_error");
          reject(parseError(err));
        });
    });
  },
  update_support_category_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("store_support_category_request");

      let url = API_ENDPOINT + `supportCategories/${data.id}`;

      axios({
        url: url,
        method: "POST",
        data: data.data,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(resp => {
          commit("store_support_category_success");
          resolve(resp);
        })
        .catch(err => {
          commit("store_support_category_error");
          reject(parseError(err));
        });
    });
  },
  store_static_page_request({ commit }, page) {
    return new Promise((resolve, reject) => {
      commit("store_static_page_request");

      let url = API_ENDPOINT + `pages`;

      axios({ url: url, method: "POST", data: page })
        .then(resp => {
          commit("store_static_page_success");
          resolve(resp);
        })
        .catch(err => {
          commit("store_static_page_error");
          reject(parseError(err));
        });
    });
  },

  get_page_categories_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_page_categories_request");

      let url = API_ENDPOINT + `page/categories`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_page_categories_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_page_categories_error");
          reject(parseError(err));
        });
    });
  },
  send_push_notification_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("send_push_notification_request");

      let url = API_ENDPOINT + `sendPushNotification`;

      if (data.options.length > 0) {
        url += "?" + data.options.join("&");
      }

      axios({
        url: url,
        data: data.data,
        method: "POST",
      })
        .then(resp => {
          commit("send_push_notification_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_customer_orders_error");
          reject(parseError(err));
        });
    });
  },
  get_dashboard_stat_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_dashboard_stat_request");

      let url = API_ENDPOINT + `getInfo`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_dashboard_stat_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_dashboard_stat_error");
          reject(parseError(err));
        });
    });
  },
  get_roles_request({ commit }, options) {
    return new Promise((resolve, reject) => {
      commit("get_roles_request");

      let url = API_ENDPOINT + `userTypes`;
      if (options.parameters && options.parameters.length > 0) {
        let questionSymbol = options.nextPageUrl ? "&" : "?";
        url += `${questionSymbol + options.parameters.join("&")}`;
      }
      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_roles_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_roles_error");
          reject(parseError(err));
        });
    });
  },
  get_role_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("get_role_request");
      let url = API_ENDPOINT + `userTypes/${data}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_role_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_role_error");
          reject(parseError(err));
        });
    });
  },
  save_role_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("save_role_request");

      let url = API_ENDPOINT + `userTypes/${data.id}`;
      axios({ url: url, data: data, method: "PUT" })
        .then(resp => {
          commit("save_role_success");
          resolve(resp);
        })
        .catch(err => {
          commit("save_role_error");
          reject(parseError(err));
        });
    });
  },
  create_role_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("create_role_request");

      let url = API_ENDPOINT + `userTypes`;

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("create_role_success");
          resolve(resp);
        })
        .catch(err => {
          commit("create_role_error");
          reject(parseError(err));
        });
    });
  },
  get_premissions_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_premissions_request");

      let url = API_ENDPOINT + `getPremissions`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_premissions_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_premissions_error");
          reject(parseError(err));
        });
    });
  },
  get_all_customers({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_all_customers");
      let url = API_ENDPOINT + "getUsers";
      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_all_customers_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_all_customers_error");
          reject(parseError(err));
        });
    });
  },
  get_media_settings_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_settings_request");
      let url = API_ENDPOINT + `mediaSettingGet`;
      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_product_success");
          resolve(resp);
        })
        .catch(err => {
          commit("get_product_error");
          reject(parseError(err));
        });
    });
  },
  save_media_settings_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("save_settings_request");
      let url = API_ENDPOINT + `mediaSettingUpdate`;
      axios({
        url: url,
        data: data,
        method: "PUT",
      })
        .then(resp => {
          commit("save_settings_success");
          resolve(resp);
        })
        .catch(err => {
          commit("save_settings_error");
          reject(parseError(err));
        });
    });
  },
  store_vacancy_department_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("store_vacancy_department_request");
      let url = API_ENDPOINT + "departments";

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("store_vacancy_department_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("store_vacancy_department_error");
          reject(parseError(err));
        });
    });
  },
  sort_vacancy_department_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("sort_vacancy_department_request");

      let url = API_ENDPOINT + `departments/sort`;

      axios({ url: url, data: { sortable: data }, method: "POST" })
        .then(resp => {
          commit("sort_vacancy_department_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("sort_vacancy_department_error");
          reject(parseError(err));
        });
    });
  },
  delete_user_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("delete_user_request");

      let url = API_ENDPOINT + `users/${id}`;

      axios({ url: url, method: "DELETE" })
        .then(resp => {
          commit("delete_user_success");
          resolve(resp);
        })
        .catch(err => {
          commit("delete_user_error");
          reject(parseError(err));
        });
    });
  },

  sort_vacancy_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("sort_vacancy_request");

      let url = API_ENDPOINT + `vacancies/sort`;

      axios({ url: url, data: { sortable: data }, method: "POST" })
        .then(resp => {
          commit("sort_vacancy_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("sort_vacancy_error");
          reject(parseError(err));
        });
    });
  },
  get_vacancy_department_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("get_vacancy_department_request");

      let url;

      if (data) {
        url = API_ENDPOINT + `departments?page=${data.page || 1}`;
      } else {
        url = API_ENDPOINT + `departments`;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_vacancy_department_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_vacancy_department_error");
          reject(parseError(err));
        });
    });
  },
  get_vacancy_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_vacancy_request");
      let url = API_ENDPOINT + `vacancies/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_vacancy_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_vacancy_error");
          reject(parseError(err));
        });
    });
  },
  get_cvs_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("get_cvs_request");
      let url = API_ENDPOINT + `resumes?page=${data.page || 1}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_cvs_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_cvs_error");
          reject(parseError(err));
        });
    });
  },
  get_cv_single_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_cv_single_request");
      let url = API_ENDPOINT + `resumes/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_cv_single_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_cv_single_error");
          reject(parseError(err));
        });
    });
  },
  get_vacancy_department_single_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("get_vacancy_department_single_request");
      let url = API_ENDPOINT + `departments/${id}`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_vacancy_department_single_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_vacancy_department_single_error");
          reject(parseError(err));
        });
    });
  },
  update_vacancy_department_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_vacancy_department_request");

      let id = data.id;

      delete data.id;

      let url = API_ENDPOINT + `departments/${id}`;

      axios({ url: url, data: data, method: "PUT" })
        .then(resp => {
          commit("update_vacancy_department_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("update_vacancy_department_error");
          reject(parseError(err));
        });
    });
  },
  delete_department_request({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("delete_department_request");

      let url = API_ENDPOINT + `departments/${id}`;

      axios({ url: url, method: "DELETE" })
        .then(resp => {
          commit("delete_department_success");
          resolve(resp);
        })
        .catch(err => {
          commit("delete_department_error");
          reject(parseError(err));
        });
    });
  },
  store_vacancy_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("store_vacancy_request");
      let url = API_ENDPOINT + "vacancies";

      axios({ url: url, data: data, method: "POST" })
        .then(resp => {
          commit("store_vacancy_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("store_vacancy_error");
          reject(parseError(err));
        });
    });
  },
  update_vacancy_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("update_vacancy_request");
      let url = API_ENDPOINT + `vacancies/${data.id}`;

      delete data.id;

      axios({ url: url, data: data, method: "PUT" })
        .then(resp => {
          commit("update_vacancy_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("update_vacancy_error");
          reject(parseError(err));
        });
    });
  },
  delete_vacancy_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("delete_vacancy_request");
      let url = API_ENDPOINT + `vacancies/${data.id}`;

      delete data.id;

      axios({ url: url, method: "DELETE" })
        .then(resp => {
          commit("delete_vacancy_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("delete_vacancy_error");
          reject(parseError(err));
        });
    });
  },
  get_vacancies_request({ commit }, data) {
    return new Promise((resolve, reject) => {
      commit("get_vacancies_request");
      let url = API_ENDPOINT + `vacancies?page=${data.page || 1}`;

      if (data.active !== null) {
        url += "&active=" + data.active;
      }

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_vacancies_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_vacancies_error");
          reject(parseError(err));
        });
    });
  },
  get_mail_templates_request({ commit }) {
    return new Promise((resolve, reject) => {
      commit("get_mail_templates_request");
      let url = API_ENDPOINT + `mails`;

      axios({ url: url, method: "GET" })
        .then(resp => {
          commit("get_mail_templates_success", resp.data);
          resolve(resp);
        })
        .catch(err => {
          commit("get_mail_templates_error");
          reject(parseError(err));
        });
    });
  },
};
const getters = {
  isLoggedIn: state => !!state.token,
  authStatus: state => state.status,
  user: state => state.user,
  customer: state => state.customer,
  userTypes: state => state.userTypes,
  userBusinessTypes: state => state.meta.user_business_types,
  authors: state => state.authors,
  meta: state => state.meta,
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
