<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Public file uploader</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Main info</h5>
          </div>

          <div class="main__content">
            <div class="main__content-in">
              <f-select
                v-model="request.folder"
                :options="['manuals']"
                title="Type"
              />

              <div class="main__content-item">
                <div class="main__content-item_l">
                  <span>File:</span>
                </div>

                <div class="main__content-item_r">
                  <media-uploader
                    key="public_file"
                    :auto-processing="false"
                    :multiple="false"
                    footer-text="Drag&drop or <u>click</u> here to upload file (pdf up to 15MB)"
                    @onRemove="removeFile"
                    @onUploadCompleted="handleFile"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Upload</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import Select from "@/components/inputs/Select";
import MediaUploader from "@/components/inputs/MediaUploader.vue";

require("verte/dist/verte.css");

export default {
  name: "ManageBuildColor",
  components: {
    MediaUploader,
    CRMLayout,
    "f-select": Select,
  },
  data() {
    return {
      request: {
        file: null,
        folder: null,
      },
    };
  },
  methods: {
    removeFile() {
      this.request.file = null;
    },
    handleFile(file) {
      this.request.file = file;
    },
    save() {
      const formData = new FormData();
      formData.append("folder", this.request.folder);
      formData.append("file", this.request.file);
      let ctx = this;
      this.$store
        .dispatch("publicUpload", formData)
        .then(result => {
          ctx.$root.$emit("modalShow", {
            text: "File link: " + result.data.path,
            type: "info",
          });
        })
        .catch(() => {
          ctx.$root.$emit("modalShow", {
            text: "Error while uploading file",
            type: "error",
          });
        });
    },
  },
};
</script>
