<template>
  <div class="main__content">
    <div class="main__content-headline">
      <h5 class="mb-4">Build info</h5>
      <h5>Basic settings</h5>

      <ul class="nav nav-tabs list-unstyled">
        <li
          v-for="language in languages"
          :key="language.prefix"
          :class="languageTabClass(language)"
        >
          <a @click.prevent="switchLanguage(language)">
            {{ language.title }}
          </a>
        </li>
      </ul>

      <div
        v-for="language in languages"
        v-show="showLanguageTab(language)"
        :key="language.prefix"
      >
        <div class="main__content tab-pane">
          <div class="main__content-in">
            <text-read-only :value="build.ms_id" title="ID (MS D)" />
            <text-read-only :value="build.id" title="ID (CRM)" />
            <checkbox-read-only :value="build.blocked" title="Blocked" />
            <text-read-only :value="build.type" title="Type" />
            <checkbox-read-only
              :value="build['available_for_compare']"
              title="Available for compare"
            />
            <text-read-only :value="build.model" title="Model/Category" />
            <text-read-only
              :value="build['build_title']"
              title="Build/Sub-category"
            />
            <checkbox-read-only
              :value="build.pinned"
              title="Pinned"
            />

            <checkbox-read-only
              v-if="build.category.id === 1"
              :value="build.dashboard"
              title="Dashboard"
            />

            <text-read-only
              v-if="build.category.id === 1 && build.bike_type"
              :value="build.bike_type.title"
              title="Bike type"
            />

            <text-read-only
              v-if="build.category.id === 1 && build.filter_category"
              :value="build.filter_category.title"
              title="Category"
            />

            <text-read-only
              v-if="build.category.id === 1 && build.build_experience"
              :value="build.build_experience.title"
              title="Experience"
            />

            <text-read-only
              v-if="build.category.id === 1 && build.build_wheel"
              :value="build.build_wheel.title"
              title="Wheel size"
            />

            <text-read-only
              v-if="build.category.id === 1 && build.build_motor"
              :value="build.build_motor.title"
              title="Motor type"
            />

            <text-read-only
              v-if="build.category.id === 1 && build.build_suspension_travel"
              :value="build.build_suspension_travel.title"
              title="Suspension travel"
            />

            <text-read-only :value="build.size" title="Size" />
            <text-read-only
              :value="build.ms_title"
              title="Bike title (from MS D)"
            />
            <text-read-only
              v-if="build['web_title']"
              :value="build['web_title']"
              title="Bike title (for web)"
            />
            <text-read-only
              v-if="build['app_title']"
              :value="build['app_title']"
              title="Bike title (for app)"
            />
            <text-read-only
              v-if="build['ms_description']"
              :value="build['ms_description']"
              title="Description (from MS D)"
            />
            <text-read-only
              v-if="build['web_description']"
              :value="build['web_description']"
              title="Description (for web)"
            />
            <text-read-only
              v-if="build['short_description']"
              :value="build['short_description']"
              title="Short Description (ex. for extra)"
            />
            <text-read-only
              :value="build['ms_color']"
              title="Colorname (from MS D)"
            />
            <text-read-only
              :value="build['color']"
              title="Colorname (for web & app)"
            />
            <single-img-read-only
              :value="build['color_image']"
              title="Color sphere img"
            />
            <text-read-only :value="buildColorPrice" title="Color price, €" />
            <checkbox-read-only :value="build['custom']" title="Custom" />
            <text-read-only :value="build['color_group']" title="Color group" />
            <text-read-only :value="build['brand']" title="Brand" />
            <text-read-only :value="build['ean']" title="EAN" />
            <text-read-only :value="build['vendor_code']" title="Vendor code" />
            <text-read-only :value="build['weight']" title="Weight, g" />
            <text-read-only :value="build['length']" title="Length, mm" />
            <text-read-only :value="build['width']" title="Width, mm" />
            <text-read-only :value="buildCreatedBy" title="Created" />
          </div>
        </div>
      </div>

      <h5 class="mt-15">Cost & stock</h5>

      <ul class="nav nav-tabs list-unstyled">
        <li
          v-for="language in languages"
          :key="language.prefix"
          :class="languageTabClass(language)"
        >
          <a @click.prevent="switchLanguage(language)">
            {{ language.title }}
          </a>
        </li>
      </ul>

      <div
        v-for="language in languages"
        v-show="showLanguageTab(language)"
        :key="`1-${language.prefix}`"
      >
        <div class="main__content tab-pane">
          <div class="main__content-in">
            <text-read-only
              :value="$crConverter(build.prices.cost / 100)"
              title="Cost, €"
            />
            <text-read-only
              :value="$crConverter(build.prices.profit / 100)"
              title="Profit, €"
            />
            <text-read-only
              :value="build.prices.profit_percent"
              title="Profit, %"
            />

            <div class="main__content-table">
              <div class="main__content-table_thead">
                <div class="tr">
                  <div class="th"></div>
                  <div class="th">Price, €</div>
                  <div class="th">Exclusive presale price, €</div>
                  <div class="th">Reservation payment, €</div>
                </div>
              </div>

              <div class="main__content-table__tbody">
                <div class="tr">
                  <div class="td"><strong>Tax Base:</strong></div>
                  <div class="td">
                    <div class="inp-group">
                      {{ $crConverter(build.prices.list.price_tax_base / 100) }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{
                        $crConverter(build.prices.list.ex_price_tax_base / 100)
                      }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{
                        $crConverter(build.prices.list.res_price_tax_base / 100)
                      }}
                    </div>
                  </div>
                </div>
                <div class="tr big">
                  <div class="td">Non EU (0%)</div>
                  <div class="td">
                    <div class="inp-group">
                      {{ $crConverter(build.prices.list.price_tax_base / 100) }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{
                        $crConverter(build.prices.list.ex_price_tax_base / 100)
                      }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{
                        $crConverter(build.prices.list.res_price_tax_base / 100)
                      }}
                    </div>
                  </div>
                </div>
                <div class="tr big">
                  <div class="td">Andorra (4,5%)</div>
                  <div class="td">
                    <div class="inp-group">
                      {{ andorraPrice(build.prices.list.price_tax_base / 100) }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{
                        andorraPrice(build.prices.list.ex_price_tax_base / 100)
                      }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{
                        andorraPrice(build.prices.list.res_price_tax_base / 100)
                      }}
                    </div>
                  </div>
                </div>

                <hr class="wcs" />

                <strong
                  class="eu"
                  style="width: 44%;font-size:14px;margin-bottom: 3px;margin-left: -10px; text-align: right;display: block;"
                >
                  EU
                </strong>

                <div
                  v-for="country in countryList"
                  :key="country.code"
                  class="tr big"
                >
                  <div class="td">{{ country.name }}</div>
                  <div class="td">
                    <div class="inp-group">
                      {{ mainPrice(country.vat) }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{ exPrice(country.vat) }}
                    </div>
                  </div>
                  <div class="td">
                    <div class="inp-group">
                      {{ resPrice(country.vat) }}
                    </div>
                  </div>
                </div>

                <div class="show-more">
                  <span class="show-more-countries" @click="showMoreCountries">
                    {{ lessOrMore }}
                  </span>
                </div>
                <hr class="wcs" />
              </div>
            </div>

            <text-read-only
              :value="formatDate(build.prices['presale_start'])"
              title="Presale, start"
            />
            <text-read-only
              :value="formatDate(build.prices['presale_finish'])"
              title="Presale, finish"
            />
            <text-read-only :value="build.prices['stock']" title="Stock" />
          </div>
        </div>
      </div>

      <h5 class="mt-15">Media content</h5>

      <ul class="nav nav-tabs list-unstyled">
        <li
          v-for="language in languages"
          :key="language.prefix"
          :class="languageTabClass(language)"
        >
          <a @click.prevent="switchLanguage(language)">
            {{ language.title }}
          </a>
        </li>
      </ul>

      <div
        v-for="language in languages"
        v-show="showLanguageTab(language)"
        :key="`2-${language.prefix}`"
      >
        <div class="main__content tab-pane">
          <div class="main__content-in">
            <single-img-read-only
              :value="build.media.ms_cover"
              title="Cover (from MS D)"
            />
            <single-img-read-only
              :value="build.media.preview"
              title="Preview images"
            />
            <multiple-img-read-only
              :value="build.media['gallery'] || []"
              title="Gallery"
            />
          </div>
        </div>
      </div>

      <h5 class="mt-15">Spec list</h5>

      <div class="main__content tab-pane">
        <div class="main__content-in">
          <div class="main__content-item">
            <div class="main__content-item_l">
              <span class="bold">Moto style:</span>
            </div>
            <div class="main__content-item_r">
              <div class="inp-group inp-group__checkbox">
                <div class="inp-group inp-group__checkbox">
                  <input
                    :checked="isMotoStyle"
                    :disabled="true"
                    id="MotoCheckbox"
                    type="checkbox"
                  />
                  <label for="MotoCheckbox"></label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div v-for="(spec, index) in build.specs" :key="index">
            <spec-read-only :value="spec" />
            <hr />
          </div>
        </div>
      </div>

      <h5 class="mt-15">Meta</h5>

      <ul class="nav nav-tabs list-unstyled">
        <li
          v-for="language in languages"
          :key="language.prefix"
          :class="languageTabClass(language)"
        >
          <a @click.prevent="switchLanguage(language)">
            {{ language.title }}
          </a>
        </li>
      </ul>

      <div
        v-for="language in languages"
        v-show="showLanguageTab(language)"
        :key="`3-${language.prefix}`"
      >
        <div class="main__content tab-pane">
          <div class="main__content-in">
            <text-read-only :value="build.meta.title" title="Title" />
            <text-read-only :value="build.meta.title" title="Description" />
            <text-read-only :value="build.meta.tags" title="Tags" />
            <text-read-only :value="build.meta.slug" title="Slug" />
            <single-img-read-only :value="build.meta.image" title="Image" />
          </div>
        </div>
      </div>

      <h5 class="mt-15">Included in delivery</h5>

      <div class="main__content tab-pane">
        <div class="main__content-in">
          <div
            v-for="(included, index) in build.included_in_delivery"
            :key="index"
          >
            <included-in-delivery-read-only :value="included" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.bold {
  font-weight: bold !important;
}
.inp-group__img {
  display: grid;
  grid-gap: 12px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  max-width: 475px;
}

.main__content-item_col {
  width: 126px;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  padding-left: 18px;
  padding-right: 0;

  &:first-child {
    padding-left: 0;
    padding-right: 16px;

    &:before {
      content: "";
      position: absolute;
      right: 0;
      top: -6px;
      height: calc(100% + 16px);
      width: 1px;
      background: #bababa;
      display: block;
    }
  }

  small {
    font-size: 10px;
    line-height: 10px;
    color: #1c1c1c;
  }
}

@media (max-width: 1600px) {
  .trans-item {
    width: 100%;
    max-width: 100%;
    padding-right: 3%;

    img {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1199px) {
  .main__content-item_col {
    width: 100px;
    padding-left: 12px;

    &:first-child {
      padding-left: 0;
      padding-right: 12px;
    }
  }
}

@media screen and (max-width: 991px) {
  .trans-item {
    padding-right: 0;
  }
  .main__content-item_r.mlt-auto {
    margin: 0 0 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .main__content-item_r.mlt-auto {
    margin: 4px 0 12px;
    width: 100%;
    padding-left: 5%;
    padding-right: 0;
  }
  .main__content-item_col {
    &:first-child:before {
      top: 2px;
      height: 100%;
    }

    &:nth-child(2) {
      padding-left: 8px !important;
    }

    width: 25%;
    padding: 0 4px !important;

    span {
      font-size: 12px;
    }
  }
}

@media screen and (max-width: 575px) {
  .inp-group__img {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 350px) {
  .inp-group__img {
    grid-template-columns: repeat(1, 1fr);
    margin-right: 100px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import TextReadOnly from "@/components/include/user-bikes/TextReadOnly";
import CheckBoxReadOnly from "@/components/include/user-bikes/CheckBoxReadOnly";
import SingleImageReadOnly from "@/components/include/user-bikes/SingleImageReadOnly";
import MultipleImageReadOnly from "@/components/include/user-bikes/MultipleImageReadOnly";
import SpecReadOnly from "@/components/include/user-bikes/SpecReadOnly";
import IncludedInDeliveryReadOnly from "@/components/include/user-bikes/IncludedInDeliveryReadOnly";

export default {
  name: "BuildReadOnly",
  components: {
    "text-read-only": TextReadOnly,
    "spec-read-only": SpecReadOnly,
    "included-in-delivery-read-only": IncludedInDeliveryReadOnly,
    "checkbox-read-only": CheckBoxReadOnly,
    "single-img-read-only": SingleImageReadOnly,
    "multiple-img-read-only": MultipleImageReadOnly
  },
  data() {
    return {
      currentLanguage: {
        prefix: "en",
        title: "EN"
      },
      languages: [
        {
          prefix: "en",
          title: "EN"
        },
        {
          prefix: "es_",
          title: "ES"
        },
        {
          prefix: "de_",
          title: "DE"
        },
        {
          prefix: "fr_",
          title: "FR"
        },
        {
          title: "RU",
          prefix: "ru_"
        }
      ],
      eu_countries: [
        {
          name: "Austria",
          code: "AT",
          vat: 20
        },
        {
          name: "Belgium",
          code: "BE",
          vat: 21
        },
        {
          name: "Bulgaria",
          code: "BG",
          vat: 20
        },
        {
          name: "Croatia",
          code: "HR",
          vat: 25
        },
        {
          name: "Cyprus",
          code: "CY",
          vat: 19
        },
        {
          name: "Czech Republic",
          code: "CZ",
          vat: 21
        },
        {
          name: "Denmark",
          code: "DK",
          vat: 25
        },
        {
          name: "Estonia",
          code: "EE",
          vat: 20
        },
        {
          name: "Finland",
          code: "FI",
          vat: 24
        },
        {
          name: "France",
          code: "FR",
          vat: 20
        },
        {
          name: "Germany",
          code: "DE",
          vat: 19
        },
        {
          name: "Greece",
          code: "GR",
          vat: 24
        },
        {
          name: "Hungary",
          code: "HU",
          vat: 27
        },
        {
          name: "Ireland",
          code: "IE",
          vat: 23
        },
        {
          name: "Italy",
          code: "IT",
          vat: 22
        },
        {
          name: "Latvia",
          code: "LV",
          vat: 21
        },
        {
          name: "Lithuania",
          code: "LT",
          vat: 21
        },
        {
          name: "Luxembourg",
          code: "LU",
          vat: 17
        },
        {
          name: "Malta",
          code: "MT",
          vat: 18
        },
        {
          name: "Netherlands",
          code: "NL",
          vat: 21
        },
        {
          name: "Poland",
          code: "PL",
          vat: 23
        },
        {
          name: "Portugal",
          code: "PT",
          vat: 23
        },
        {
          name: "Romania",
          code: "RO",
          vat: 19
        },
        {
          name: "Slovakia",
          code: "SK",
          vat: 20
        },
        {
          name: "Slovenia",
          code: "SI",
          vat: 22
        },
        {
          name: "Spain",
          code: "ES",
          vat: 21
        },
        {
          name: "Sweden",
          code: "SE",
          vat: 25
        }
      ],
      shortCountryList: true
    };
  },
  computed: {
    ...mapGetters({
      bike: "bikes/bike"
    }),
    isMotoStyle() {
      return this.bike.moto_style;
    },
    lessOrMore() {
      return this.shortCountryList ? "Show more" : "Show less";
    },
    countryList() {
      let countries = [...this.eu_countries];
      if (this.shortCountryList) {
        return countries.splice(0, 2);
      }

      return countries;
    },
    build() {
      return this.bike["full_build"];
    },
    buildColorPrice() {
      return this.build["color_price"] === 0
        ? "Free"
        : this.build["color_price"];
    },
    buildCreatedBy() {
      let date = this.$moment
        .unix(this.build["created_at"])
        .format("DD.MM.YYYY hh:mm:ss");
      let creator = this.build["created_by"];
      return date + " by " + creator;
    }
  },
  methods: {
    showMoreCountries() {
      this.shortCountryList = !this.shortCountryList;
    },
    andorraPrice(base) {
      return this.$crConverter(
        parseFloat(base) + parseFloat((base / 100) * 4.5)
      );
    },
    mainPrice(vat) {
      let taxBase = this.build.prices.list.price_tax_base / 100;

      return this.$crConverter(
        parseFloat(taxBase) + parseFloat((taxBase / 100) * vat)
      );
    },
    exPrice(vat) {
      let taxBase = this.build.prices.list.ex_price_tax_base / 100;

      return this.$crConverter(
        parseFloat(taxBase) + parseFloat((taxBase / 100) * vat)
      );
    },
    resPrice(vat) {
      let taxBase = this.build.prices.list.res_price_tax_base / 100;

      return this.$crConverter(
        parseFloat(taxBase) + parseFloat((taxBase / 100) * vat)
      );
    },
    formatDate(date) {
      if (date !== null) {
        return this.$moment.unix(date).format("DD.MM.YYYY");
      }

      return "Not set";
    },
    languageTabClass(language) {
      return {
        active: this.showLanguageTab(language)
      };
    },
    isLanguageActive(language) {
      return language.title === this.currentLanguage.title;
    },
    switchLanguage(language) {
      this.currentLanguage = language;
    },
    showLanguageTab(language) {
      return this.isLanguageActive(language);
    }
  }
};
</script>
