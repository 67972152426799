<template>
  <layout>
    <template v-slot:header-title>
      <span>Creating products model</span>
    </template>

    <template v-slot:content>
      <div class="main__c main__c-auto">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Model info</h5>
          </div>

          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.formNamePrefix"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">
                    {{ language.title }}
                  </a>
                </li>
              </ul>

              <div class="main__content tab-pane">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="language.formNamePrefix"
                    class="mb-3"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="$data[language.formNamePrefix + 'title']"
                            autofocus
                            title="Title"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item" style="margin-bottom: 15px">
                      <div class="main__content-item_l">
                        <span>Short Description:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <ckeditor
                            v-model="$data[language.formNamePrefix + 'desc']"
                            :config="editorConfig"
                            :editor="editor"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item" style="margin-bottom: 15px">
                      <div class="main__content-item_l">
                        <span>Second Short Description:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <ckeditor
                            v-model="
                              $data[language.formNamePrefix + 'second_desc']
                            "
                            :config="editorConfig"
                            :editor="editor"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="main__content-item main__content-item_center mb-5"
                      style="margin-bottom: 15px"
                    >
                      <div class="main__content-item_l">
                        <span>Delivery date:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data[language.formNamePrefix + 'delivery_info']
                            "
                            autofocus
                            title="Delivery date"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="main__content-item main__content-item_center mb-5"
                      style="margin-bottom: 15px"
                    >
                      <div class="main__content-item_l">
                        <span>Carousel description:</span>
                      </div>

                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data[
                                language.formNamePrefix + 'carousel_description'
                              ]
                            "
                            autofocus
                            title="Carousel description"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>User manuals:</span>
                      </div>

                      <div class="main__content-item_r dropzone-show-text">
                        <media-uploader
                          key="manuals"
                          :auto-processing="false"
                          :media="manuals"
                          :multiple="false"
                          footer-text="Drag&drop or <u>click</u> here to upload manual file (pdf up to 15MB)"
                          type="document"
                          @onRemove="deleteTempManuals($event)"
                          @onUploadCompleted="saveTempManuals($event)"
                        />
                      </div>
                    </div>

                    <div class="main__content-item">
                      <div class="main__content-item_l">
                        <span>Suspension setup guide:</span>
                      </div>

                      <div class="main__content-item_r dropzone-show-text">
                        <media-uploader
                          key="suspensions"
                          :auto-processing="false"
                          :media="suspensions"
                          :multiple="false"
                          footer-text="Drag&drop or <u>click</u> here to upload suspension guide file (pdf up to 15MB)"
                          type="document"
                          @onRemove="deleteTempSuspension($event)"
                          @onUploadCompleted="saveTempSuspension($event)"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Coming Soon:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-1"
                          v-model="coming_soon"
                          type="checkbox"
                        />
                        <label for="checkbox-1"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Available for purchase:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-3"
                          v-model="purchasable"
                          type="checkbox"
                        />
                        <label for="checkbox-3"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Show in menu:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-4"
                          v-model="show_menu"
                          type="checkbox"
                        />
                        <label for="checkbox-4"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Frame number selection:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group inp-group__checkbox">
                        <input
                          id="checkbox-2"
                          v-model="frame_number_selection"
                          type="checkbox"
                        />
                        <label for="checkbox-2"></label>
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item main__content-item_center">
                    <div class="main__content-item_l">
                      <span>Reserved numbers:</span>
                    </div>

                    <div class="main__content-item_r">
                      <div class="inp-group">
                        <vue-tags-input
                          v-model="reservedNumber"
                          :tags="reserved"
                          placeholder="Add frame number"
                          @before-adding-tag="checkTag($event)"
                          @tags-changed="
                            newTags => {
                              this.reserved = newTags;
                            }
                          "
                        />
                      </div>
                    </div>
                  </div>

                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Model image:</span>
                    </div>

                    <div class="main__content-item_r">
                      <media-uploader
                        key="model_image"
                        :auto-processing="false"
                        :media="default_image"
                        :multiple="false"
                        footer-text="Drag&drop or <u>click here</u> to upload image (jpg, 2800x1575 up to 15MB)"
                        type="image"
                        @onRemove="deleteTempImage($event)"
                        @onUploadCompleted="saveTempImages($event)"
                      />
                    </div>
                  </div>

                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Model image(carousel):</span>
                    </div>

                    <div class="main__content-item_r">
                      <media-uploader
                        key="slider_image"
                        :auto-processing="false"
                        :media="default_carousel_img"
                        :multiple="false"
                        footer-text="Drag&drop or <u>click</u> here to upload image (jpg 1860x850 up to 15MB)"
                        type="image"
                        @onRemove="deleteTempCarouselImage($event)"
                        @onUploadCompleted="saveTempCarouselImage($event)"
                      />
                    </div>
                  </div>

                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Model video:</span>
                    </div>

                    <div class="main__content-item_r">
                      <media-uploader
                        key="model_video"
                        :auto-processing="false"
                        :media="default_video"
                        :multiple="false"
                        footer-text="Drag&drop or <u>click</u> here to upload video (mp4 1920x1080 up to 150MB)"
                        type="video"
                        @onRemove="deleteTempVideo($event)"
                        @onUploadCompleted="saveTempVideo($event)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="main__content-btns">
            <button class="btn btn-big btn-fw" @click="save">
              <span><i>Save model</i></span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </layout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import { STORAGE_ENDPOINT } from "@/utils/api";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueTagsInput from "@johmun/vue-tags-input";
import MediaUploader from "@/components/inputs/MediaUploader.vue";

export default {
  name: "CRMProductModelShow",
  components: {
    MediaUploader,
    layout: CRMLayout,
    ckeditor: CKEditor["component"],
    VueTagsInput,
  },
  data() {
    return {
      title: null,
      es_title: null,
      de_title: null,
      fr_title: null,
      ru_title: null,
      desc: null,
      es_desc: null,
      de_desc: null,
      fr_desc: null,
      ru_desc: null,
      fr_second_desc: null,
      de_second_desc: null,
      second_desc: null,
      es_second_desc: null,
      ru_second_desc: null,
      coming_soon: false,
      purchasable: true,
      show_menu: true,
      frame_number_selection: false,
      with_custom_color: false,
      custom_color_tax_base: null,
      custom_color_price_for_eu: null,
      custom_color_price_for_andorra: null,
      custom_color_price_for_other: null,
      img: null,
      slider_img: null,
      video: null,
      reserved: [],
      reservedNumber: "",
      oldReservedNumber: "",
      delivery_info: "",
      es_delivery_info: "",
      de_delivery_info: "",
      fr_delivery_info: "",
      ru_delivery_info: "",
      carousel_description: "",
      manual_link: "",
      partCategoriesAsArray: [],
      partCategories: {},
      default_image: null,
      manuals: [],
      suspensions: [],
      manuals_for_delete: [],
      suspensions_for_delete: [],
      default_video: null,
      default_carousel_img: null,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: ["bold", "italic", "|", "undo", "redo"],
      },
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: "",
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_",
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_",
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_",
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_",
        },
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: "",
      },
    };
  },
  async created() {
    await this.loadPartCategories();
  },
  methods: {
    rearrange(oldIndex, newIndex) {
      let array = this.$data["partCategoriesAsArray"];
      if (oldIndex > newIndex) {
        array.splice(newIndex, 0, array[oldIndex]);
        array.splice(oldIndex + 1, 1);
      } else {
        array.splice(newIndex + 1, 0, array[oldIndex]);
        array.splice(oldIndex, 1);
      }
    },
    checkTag(tagObject) {
      let tagText = tagObject.tag.text;

      let regExp = new RegExp("^\\d+$");
      let isValid = regExp.test(tagText);

      if (tagText.length === 3 && isValid) {
        tagObject.addTag();
      } else {
        if (this.oldReservedNumber !== tagText) {
          this.$root.$emit("modalShow", {
            type: "error",
            text: "Frame number must be at least 3 numeric symbols",
          });
        }
      }

      this.oldReservedNumber = tagText;
    },
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    saveTempImages(file) {
      this.img = file;
    },
    saveTempManuals(file) {
      this.manuals = [...this.manuals, ...[file]];
    },
    saveTempSuspension(file) {
      this.suspensions = [...this.suspensions, ...[file]];
    },
    deleteTempManuals(file) {
      // eslint-disable-next-line
      if (file.constructor.name === "Object" && file.hasOwnProperty("name")) {
        this.manuals_for_delete.push(file.name);
      }

      this.manuals = this.manuals.filter(manual => {
        return manual !== file;
      });
    },
    deleteTempSuspension(file) {
      // eslint-disable-next-line
      if (file.constructor.name === "Object" && file.hasOwnProperty("name")) {
        this.suspensions_for_delete.push(file.name);
      }
      this.suspensions = this.suspensions.filter(suspension => {
        return suspension !== file;
      });
    },
    deleteTempImage() {
      this.default_image = null;
      this.img = null;
    },
    saveTempCarouselImage(file) {
      this.slider_img = file;
    },
    deleteTempCarouselImage() {
      this.default_carousel_img = null;
      this.slider_img = null;
    },
    saveTempVideo(file) {
      this.video = file;
    },
    deleteTempVideo() {
      this.default_video = null;
      this.video = null;
    },
    async loadModel() {
      let ctx = this;
      this.$store
        .dispatch("get_product_model_request", this.$route.params.id)
        .then(response => {
          ctx.title = response.data.model;
          ctx.es_title = response.data["es_model"];
          ctx.de_title = response.data["de_model"];
          ctx.fr_title = response.data["fr_model"];
          ctx.ru_title = response.data["ru_model"];
          ctx.desc = response.data.desc;
          ctx.es_desc = response.data.es_desc;
          ctx.de_desc = response.data.de_desc;
          ctx.fr_desc = response.data.fr_desc;
          ctx.ru_desc = response.data.ru_desc;
          ctx.fr_second_desc = response.data.fr_second_desc;
          ctx.de_second_desc = response.data.de_second_desc;
          ctx.second_desc = response.data.second_desc;
          ctx.es_second_desc = response.data.es_second_desc;
          ctx.ru_second_desc = response.data.ru_second_desc;
          ctx.with_custom_color = !!response.data.with_custom_color;
          ctx.custom_color_tax_base = response.data.custom_color_tax_base / 100;
          ctx.custom_color_price_for_eu =
            response.data.custom_color_price_for_eu / 100;
          ctx.custom_color_price_for_andorra =
            response.data.custom_color_price_for_andorra / 100;
          ctx.custom_color_price_for_other =
            response.data.custom_color_price_for_other / 100;
          ctx.reserved = response.data.reserved
            ? response.data.reserved.map(item => {
              return {
                text: item,
              };
            })
            : [];
          ctx.coming_soon = !!response.data.coming_soon;
          ctx.purchasable = !!response.data.purchasable;
          ctx.show_menu = !!response.data.show_menu;
          ctx.frame_number_selection = !!response.data.frame_number_selection;
          ctx.default_image = response.data.img
            ? STORAGE_ENDPOINT + response.data.img
            : null;
          ctx.manuals = response.data.manuals
            ? response.data.manuals.map(manual => {
              return STORAGE_ENDPOINT + manual.path;
            })
            : [];
          ctx.suspensions = response.data.suspensions
            ? response.data.suspensions.map(suspension => {
              return STORAGE_ENDPOINT + suspension.path;
            })
            : [];
          ctx.default_video = response.data.video
            ? STORAGE_ENDPOINT + response.data.video
            : null;
          ctx.default_carousel_img = response.data.slider_img
            ? STORAGE_ENDPOINT + response.data.slider_img
            : null;

          if (
            response.data["rel_cats_quantity"] &&
            response.data["rel_cats_quantity"].length > 0
          ) {
            for (let category of response.data["rel_cats_quantity"]) {
              ctx.partCategories[category.id] = {
                quantity: parseInt(category["pivot"].quantity),
                title: category.en_title,
                sort_order: category["pivot"].sort_order,
              };
            }

            ctx.partCategoriesAsArray = ctx.partCategoriesAsArray.map(item => {
              return {
                id: item.id,
                en_title: item.en_title,
                sort_order: ctx.partCategories[item.id].sort_order || 999,
              };
            });

            ctx.partCategoriesAsArray = ctx.partCategoriesAsArray.sort((a, b) =>
              a.sort_order > b.sort_order ? 1 : -1,
            );
          }

          ctx.delivery_info = response.data.delivery_info;
          ctx.es_delivery_info = response.data.es_delivery_info;
          ctx.de_delivery_info = response.data.de_delivery_info;
          ctx.fr_delivery_info = response.data.fr_delivery_info;
          ctx.ru_delivery_info = response.data.ru_delivery_info;
          ctx.carousel_description = response.data.carousel_description;
          ctx.manual_link = response.data.manual_link;
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    async loadPartCategories() {
      let ctx = this;
      this.$store
        .dispatch("get_part_categories_request", { type: "all" })
        .then(response => {
          ctx.partCategoriesAsArray = response.data.map(item => {
            return {
              id: item.id,
              en_title: item.en_title,
            };
          });

          let categories = {};

          for (let category of response.data) {
            categories[category.id] = {
              quantity: 0,
              title: category.en_title,
            };
          }

          ctx.partCategories = categories;

          this.loadModel();
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    getCategoryById(id) {
      let categories = this.partCategoriesAsArray.filter(item => {
        return parseInt(item.id) === parseInt(id);
      });
      return categories[0] || null;
    },
    save() {
      let ctx = this;
      let data = new FormData();

      data.append("model", this.title);
      data.append("es_model", this.es_title);
      data.append("de_model", this.de_title);
      data.append("fr_model", this.fr_title);
      data.append("ru_model", this.ru_title);
      data.append("desc", this.desc);
      data.append("es_desc", this.es_desc);
      data.append("de_desc", this.de_desc);
      data.append("fr_desc", this.fr_desc);
      data.append("ru_desc", this.ru_desc);
      data.append("fr_second_desc", this.fr_second_desc);
      data.append("de_second_desc", this.de_second_desc);
      data.append("second_desc", this.second_desc);
      data.append("es_second_desc", this.es_second_desc);
      data.append("ru_second_desc", this.ru_second_desc);
      data.append("coming_soon", this.coming_soon ? "1" : "0");
      data.append("purchasable", this.purchasable ? "1" : "0");
      data.append("show_menu", this.show_menu ? "1" : "0");
      data.append(
        "frame_number_selection",
        this.frame_number_selection ? "1" : "0",
      );
      data.append("img", this.img);
      if (this.manuals.length > 0) {
        for (let manual of this.manuals) {
          data.append("manuals[]", manual);
        }
      }
      if (this.manuals_for_delete.length > 0) {
        data.append("manuals_for_delete", this.manuals_for_delete.join(";"));
      }
      if (this.suspensions.length > 0) {
        for (let suspension of this.suspensions) {
          data.append("suspensions[]", suspension);
        }
      }
      if (this.suspensions_for_delete.length > 0) {
        data.append(
          "suspensions_for_delete",
          this.suspensions_for_delete.join(";"),
        );
      }
      data.append("video", this.video);
      data.append("slider_img", this.slider_img);
      data.append("with_custom_color", this.with_custom_color ? "1" : "0");
      data.append("custom_color_tax_base", this.custom_color_tax_base);
      data.append("custom_color_price_for_eu", this.custom_color_price_for_eu);
      data.append(
        "custom_color_price_for_andorra",
        this.custom_color_price_for_andorra,
      );
      data.append(
        "custom_color_price_for_other",
        this.custom_color_price_for_other,
      );
      data.append(
        "reserved",
        JSON.stringify(
          this.reserved.map(item => {
            return item.text;
          }),
        ),
      );
      data.append("delivery_info", this.delivery_info);
      data.append("es_delivery_info", this.es_delivery_info);
      data.append("de_delivery_info", this.de_delivery_info);
      data.append("fr_delivery_info", this.fr_delivery_info);
      data.append("ru_delivery_info", this.ru_delivery_info);
      data.append("carousel_description", this.carousel_description);
      data.append("manual_link", this.manual_link);

      let categories = [];

      ctx.partCategoriesAsArray.forEach(value => {
        categories.push({
          cat_id: value.id,
          quantity: ctx.partCategories[value.id].quantity,
        });
      });

      for (let key of Object.keys(ctx.partCategories)) {
        let cat = this.getCategoryById(key);

        if (!cat) {
          categories.push({
            cat_id: key,
            quantity: ctx.partCategories[key].quantity,
          });
        }
      }

      categories = JSON.stringify(categories);

      data.append("categories", categories);

      this.$store
        .dispatch("update_product_model_request", {
          data: data,
          id: this.$route.params.id,
        })
        .then(() => {
          ctx.$root.$emit("modalShow", {
            text: "Model successfully updated",
            type: "info",
          });
          ctx.$router.push("/products/models/");
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { text: err, type: "error" });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-category {
  display: flex;
  width: 95%;
  background: rgba(204, 204, 204, 0.73);
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 6px;

  * {
    user-select: none;
  }

  .handler {
    width: 40px;
    height: 40px;
    background: url(../assets/img/icons/burger.svg) center center no-repeat;
    background-size: 50%;
    margin: auto 10px auto 10px;
    cursor: pointer;
  }

  .data {
    display: flex;
    flex-direction: column;
    width: 100%;

    .block {
      width: 70%;
      margin-bottom: 10px;
    }

    input {
      border: none;
      -webkit-box-shadow: 0 0 2px 0 #000;
      box-shadow: 0 0 2px 0 #000;
      border-radius: 5px;
      height: 30px;
      line-height: 33px;
      padding: 0 10px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      display: block;
      width: 100%;
    }
  }
}

</style>

<style>
.ti-input {
  border: none !important;
  padding: 0 !important;
}

.ti-new-tag-input-wrapper {
  padding: 0;
}

.ti-new-tag-input-wrapper input {
  padding-left: 8px !important;
}

.ti-tag {
  background: #c2e900 !important;
  color: #000000 !important;
  height: 30px !important;
  margin: 4px 4px 4px 0 !important;
  padding: 0 8px !important;
  border-radius: 10px !important;
}

@media screen and (max-width: 1300px) {
  .drag-category {
    width: calc(100% - 20px) !important;
    box-sizing: border-box;
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  .main__content-headline h5 {
    padding-left: 20px;
  }

  .ti-new-tag-input-wrapper {
    padding: 0 !important;
    margin: 0 !important;
  }
}
</style>
