<template>
  <CRMLayout>
    <template v-slot:header-title>
      <span>Creating post</span>
    </template>
    <template v-slot:content>
      <div class="main__c bg-transparent">
        <div class="main__c-in">
          <div class="main__headline">
            <h5>Publication settings</h5>
          </div>
          <div class="main__content">
            <div class="">
              <ul class="nav nav-tabs list-unstyled">
                <li
                  v-for="language in languages"
                  :key="language.formNamePrefix"
                  :class="isActive(language) ? 'active' : ''"
                >
                  <a @click="switchActiveLanguage(language)">{{
                      language.title
                    }}</a>
                </li>
              </ul>
              <div class="main__content tab-pane">
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="language.formNamePrefix"
                  >
                    <div class="main__content-item main__content-item_center">
                      <div class="main__content-item_l">
                        <span>Title:</span>
                      </div>
                      <div class="main__content-item_r">
                        <div class="inp-group inp-group__xl">
                          <input
                            v-model="
                              $data.post[language.formNamePrefix + 'title']
                            "
                            :name="language.formNamePrefix + 'title'"
                            title="Title"
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="main__content-item main__content-margin_bottom">
                      <div class="main__content-item_l">
                        <span>Description:</span>
                      </div>
                      <div class="main__content-item_r">
                        <div
                          class="inp-group inp-group__textarea inp-group__xl"
                        >
                          <textarea
                            v-model="
                              $data.post[language.formNamePrefix + 'desc']
                            "
                            :class="'wysiswyg_' + language.id"
                            :name="language.formNamePrefix + 'desc'"
                            title="Description"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Post image:</span>
                    </div>
                    <div class="main__content-item_r">
                      <media-uploader
                        key="news_img"
                        :auto-processing="false"
                        :multiple="false"
                        footer-text="Drag&drop or <u>click</u> here to upload image (jpg 2500x1406 up to 15MB)"
                        type="image"
                        @onRemove="deleteTempImage('')"
                        @onUploadCompleted="saveTempImages($event, '')"
                      />
                    </div>
                  </div>
                  <div class="main__content-item">
                    <div class="main__content-item_l">
                      <span>Post header image:</span>
                    </div>
                    <div class="main__content-item_r">
                      <media-uploader
                        key="header_news_img"
                        :auto-processing="false"
                        :multiple="false"
                        footer-text="Drag&drop or <u>click</u> here to upload image (jpg 2500x1406 up to 15MB)"
                        type="image"
                        @onRemove="deleteTempImage('header_')"
                        @onUploadCompleted="saveTempImages($event, 'header_')"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="main__content main__content-content tab-pane">
            <div class="main__content-headline">
              <h5>Content</h5>
              <div>
                <ul class="nav nav-tabs list-unstyled">
                  <li
                    v-for="language in languages"
                    :key="language.formNamePrefix"
                    :class="isActive(language) ? 'active' : ''"
                  >
                    <a @click="switchActiveLanguage(language)">{{
                        language.title
                      }}</a>
                  </li>
                </ul>
                <div class="main__content-in">
                  <div
                    v-for="language in languages"
                    v-show="activeLanguage.id === language.id"
                    :key="language.formNamePrefix"
                  >
                    <div class="main__content-types">
                      <ContentEditor
                        :lang="language.id"
                        :prefix="language.formNamePrefix"
                        :source-sections="
                          $data[language.formNamePrefix + 'sections']
                        "
                        @endSort="endSort($event, language.formNamePrefix)"
                        @removeSection="removeSection($event)"
                        @updateContent="
                          updateContent($event, language.formNamePrefix)
                        "
                        @updateStyle="setTextStyle($event)"
                      />
                    </div>
                  </div>
                  <div class="post__btns">
                    <button
                      class="btn btn-icon btn-text post__btn"
                      @click="addSection('text')"
                    >
                      <span class="icon"></span>
                      <span>Add text</span>
                    </button>
                    <button
                      class="btn btn-icon btn-img post__btn"
                      @click="addSection('image')"
                    >
                      <span class="icon"></span>
                      <span>Add image (-s)</span>
                    </button>
                    <button
                      class="btn btn-icon btn-video post__btn"
                      @click="addSection('video')"
                    >
                      <span class="icon"></span>
                      <span>Add video</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main__r main__r-products">
        <div class="main__headline">
          <h5>Publication settings</h5>
        </div>
        <div class="main__r-in">
          <div class="inp-group__wrapper inp-group__from-to">
            <div class="inp-group__headline">
              <h5>Published</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group__radio-wrapper">
                <div class="inp-group__radio">
                  <input
                    id="on"
                    v-model="post.active"
                    :value="true"
                    name="radio"
                    type="radio"
                  />
                  <label for="on">ON</label>
                </div>
                <div class="inp-group__radio">
                  <input
                    id="off"
                    v-model="post.active"
                    :value="false"
                    name="radio"
                    type="radio"
                  />
                  <label for="off">OFF</label>
                </div>
              </div>
            </div>
          </div>
          <div class="inp-group__wrapper">
            <div class="inp-group__headline">
              <h5>Start activity</h5>
            </div>
            <div class="inp-group__in">
              <div class="inp-group">
                <date-picker
                  v-model="post.public_period"
                  :format="'YYYY/MM/DD'"
                  type="date"
                  valueType="YYYY-MM-DD"
                />
              </div>
            </div>
          </div>
          <div class="fix-wrap">
            <div class="btn__wrapper">
              <button
                class="btn btn-gray btn-reset btn-fw btn-bold btn-lg"
                @click="publish"
              >
                SAVE
              </button>
            </div>
            <div class="btn__wrapper">
              <button
                class="btn btn-white btn-reset btn-fw btn-bold btn-lg"
                @click="preview"
              >
                PREVIEW
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </CRMLayout>
</template>

<script>
import CRMLayout from "./CRMLayout";
import { HandleDirective } from "vue-slicksort";
import DatePicker from "vue2-datepicker";
import { API_BASE_PATH, API_ENDPOINT } from "@/utils/api";
import ContentEditor from "./forms/ContentEditor";
import MediaUploader from "@/components/inputs/MediaUploader.vue";

export default {
  name: "CRMPostCreate",
  components: { MediaUploader, CRMLayout, DatePicker, ContentEditor },
  directives: { handle: HandleDirective },
  data() {
    return {
      post: {
        title: "",
        es_title: "",
        de_title: "",
        fr_title: "",
        ru_title: "",
        desc: "",
        es_desc: "",
        de_desc: "",
        fr_desc: "",
        ru_desc: "",
        link: "",
        es_link: "",
        de_link: "",
        fr_link: "",
        ru_link: "",
        news_img: null,
        header_news_img: null,
        public_period: null,
        active: false,
      },
      settings: {
        uploadURL: API_ENDPOINT + "uploadFile",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          Accept: "application/json",
        },
      },
      dragData: {},
      sections: [],
      es_sections: [],
      de_sections: [],
      fr_sections: [],
      ru_sections: [],
      languages: [
        {
          id: "en",
          title: "EN",
          formNamePrefix: "",
        },
        {
          id: "es",
          title: "ES",
          formNamePrefix: "es_",
        },
        {
          id: "de",
          title: "DE",
          formNamePrefix: "de_",
        },
        {
          id: "fr",
          title: "FR",
          formNamePrefix: "fr_",
        },
        {
          id: "ru",
          title: "RU",
          formNamePrefix: "ru_",
        },
      ],
      activeLanguage: {
        id: "en",
        title: "EN",
        formNamePrefix: "",
      },
      notRemoved: false,
    };
  },
  methods: {
    setTextStyle(event) {
      for (let language of this.languages) {
        this.$data[`${language.formNamePrefix}sections`] = this.$data[
          `${language.formNamePrefix}sections`
          ].map(single => {
          if (single.id === event.sectionId) {
            single.style = event.style;
            return single;
          } else {
            return single;
          }
        });
      }
    },
    removeSection(index) {
      for (let language of this.languages) {
        this.$data[language.formNamePrefix + "sections"] = this.$data[
        language.formNamePrefix + "sections"
          ].filter(item => {
          return item.id !== index;
        });
      }
    },
    updateContent(event, prefix) {
      this.$data[prefix + "sections"] = event;
    },
    endSort(event, prefix) {
      for (let language of this.languages) {
        if (language.formNamePrefix !== prefix) {
          let sections;
          sections = [];
          for (let exampleSection of event) {
            let result = this.$data[language.formNamePrefix + "sections"].find(
              item => {
                return item.id === exampleSection.id;
              },
            );
            if (result) {
              sections.push(result);
            }
          }
          this.$data[language.formNamePrefix + "sections"] = sections;
        }
      }
    },
    addSection(type) {
      switch (type) {
        case "text":
          this.addTextSection();
          break;
        case "image":
          this.addImageSection();
          break;
        case "video":
          this.addVideoSection();
          break;
      }
    },
    addTextSection() {
      for (let language of this.languages) {
        this.$data[language.formNamePrefix + "sections"].push({
          id: this.getLastSectionId(language.formNamePrefix),
          type: "text",
          content: null,
          style: "main-text",
        });
      }
    },
    addImageSection() {
      for (let language of this.languages) {
        this.$data[language.formNamePrefix + "sections"].push({
          id: this.getLastSectionId(language.formNamePrefix),
          type: "image",
          images: [],
        });
      }
    },
    addVideoSection() {
      for (let language of this.languages) {
        this.$data[language.formNamePrefix + "sections"].push({
          id: this.getLastSectionId(language.formNamePrefix),
          type: "video",
          video: null,
        });
      }
    },
    getLastSectionId(prefix) {
      try {
        if (this.$data[`${prefix}sections`].length === 0) {
          return 1;
        }

        let max = 0;
        for (let section of this.$data[`${prefix}sections`]) {
          if (section.id > max) {
            max = section.id;
          }
        }
        return max + 1;
      } catch (e) {
        throw Error("Error, try later");
      }
    },
    sortend(event) {
      const { oldIndex, newIndex } = event;
      this.rearrange(oldIndex, newIndex);
    },
    rearrange(oldIndex, newIndex) {
      for (let language of this.languages) {
        let array = this.$data[`${language.formNamePrefix}sections`];
        if (oldIndex > newIndex) {
          array.splice(newIndex, 0, array[oldIndex]);
          array.splice(oldIndex + 1, 1);
        } else {
          array.splice(newIndex + 1, 0, array[oldIndex]);
          array.splice(oldIndex, 1);
        }
        this.$set(this.$data, `${language.formNamePrefix}sections`, array);
      }
    },
    deleteTempImage(prefix) {
      this.post[`${prefix}news_img`] = null;
    },
    saveTempImages(file, prefix) {
      this.post[`${prefix}news_img`] = file;
    },
    publish() {
      let ctx = this;
      let data = this.getFormData();

      this.$store
        .dispatch("create_post_request", data)
        .then(resp => {
          ctx.$router.push("/posts/" + resp.data.en.id);
          ctx.$root.$emit("modalShow", {
            type: "info",
            text: "Post created successfully",
          });
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    getFormData() {
      let ctx = this;
      let data = new FormData();
      for (let key of Object.keys(ctx.post)) {
        if (ctx.post[key] !== null && ctx.post[key] !== "null") {
          if (key === "active") {
            data.append(key, ctx.post[key] ? "1" : "0");
          } else {
            data.append(key, ctx.post[key]);
          }
        }
      }
      data.append("full_desc", JSON.stringify(ctx.sections));
      data.append("es_full_desc", JSON.stringify(ctx.es_sections));
      data.append("de_full_desc", JSON.stringify(ctx.de_sections));
      data.append("fr_full_desc", JSON.stringify(ctx.fr_sections));
      data.append("ru_full_desc", JSON.stringify(ctx.ru_sections));
      return data;
    },
    preview() {
      let ctx = this;
      let data = this.getFormData();
      this.$store
        .dispatch("create_post_request", data)
        .then(resp => {
          let url =
            API_BASE_PATH +
            "/" +
            ctx.activeLanguage.id +
            `/preview/` +
            resp.data.en.slug;
          window.open(url);
          ctx.$router.push("/posts/" + resp.data.en.id);
        })
        .catch(err => {
          ctx.$root.$emit("modalShow", { type: "error", text: err });
        });
    },
    switchActiveLanguage(language) {
      this.activeLanguage = language;
    },
    isActive(language) {
      return language.id === this.activeLanguage.id;
    },
  },
};
</script>

<style>
.main__content-margin_bottom {
  margin-bottom: 15px !important;
}
</style>
