<template>
  <div>
    <div v-for="album in albums" :key="`album-${album.id}`">
      <div class="tr">
        <div class="td"></div>
        <div class="id td">{{ album.id }}</div>
        <div class="td name pr-3">
          <a href="#"
             @click.prevent="showAlbum(album.id)">{{ album.languages.en.title ? album.languages.en.title : "Unnamed"
            }}</a>
        </div>
        <div class="td">{{ album.files.length }}</div>
        <div class="td publish">
          <button :class="{'active': album.active, disabled: disabledItems.includes(album.id)}"
                  class="btn-activity mmt-1-5 mmb-1-5 mr-3"
                  @click="toggleAlbumActive(album)">
            {{ album.active ? "Published" : "Unpublished" }}
          </button>
        </div>
        <div class="td d-flex items-center pen">
          <button class="btn-link btn-blue-link" @click.prevent="showAlbum(album.id)">
            <svg class="block mmt-1-5 mmb-1-5" fill="none" height="24" viewBox="0 0 24 24" width="24"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 5.99985L18 9.99985M14 5.99985L16.293 3.70685C16.4805 3.51938 16.7348 3.41406 17 3.41406C17.2652 3.41406 17.5195 3.51938 17.707 3.70685L20.293 6.29285C20.4805 6.48038 20.5858 6.73468 20.5858 6.99985C20.5858 7.26501 20.4805 7.51932 20.293 7.70685L18 9.99985L14 5.99985ZM14 5.99985L4.293 15.7068C4.10545 15.8943 4.00006 16.1487 4 16.4138V18.9998C4 19.2651 4.10536 19.5194 4.29289 19.707C4.48043 19.8945 4.73478 19.9998 5 19.9998H7.586C7.85119 19.9998 8.10551 19.8944 8.293 19.7069L18 9.99985L14 5.99985Z"
                stroke="#454545"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2" />
            </svg>
          </button>
        </div>
      </div>
      <template v-if="album.children.length">
        <div style="padding-left: 20px">
          <media-tree v-bind:albums="album.children" v-bind:filters="filters" v-bind:show-album="showAlbum" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "MediaTree",
  props: [
    "albums",
    "showAlbum",
    "filters",
  ],
  data() {
    return {
      disabledItems: [],
    };
  },
  methods: {
    toggleAlbumActive(album) {
      if (!this.disabledItems.includes(album.id)) {
        this.disabledItems.push(album.id);
        album.active = !album.active;

        this
          .$store
          .dispatch("mediaItem/update_album", album)
          .then(() => {
            this.$store.dispatch("mediaList/get_media_request", this.filters);
          })
          .finally(() => {
            setTimeout(() => {
              this.disabledItems = this.disabledItems.filter(item => item !== album.id);
            }, 200);
          });
      }
    },
    deleteAlbum(id) {
      this.$dialog
        .confirm("Are you sure you want to delete album?")
        .then(() => this.$modal.hide("edit-album"))
        .then(() => {
          this
            .$store.dispatch("mediaItem/deleteAlbum", id)
            .then(() => {
              this.$store.dispatch("mediaList/get_media_request", this.filters);
            })
            .catch(err => {
              this.$root.$emit("modalShow", { text: err, type: "error" });

              this.$store.dispatch("mediaList/get_media_request", this.filters);
            });
        })
        .catch(() => {
        });
    },
  },
};
</script>
