<template>
  <div class="trans-item">
    <div class="btn-burger centered pointer"></div>
    <div class="wrapper">
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <p class="inp-group__text">Securing strap</p>
        </div>
      </div>
      <div class="main__content-item main__content-item_center">
        <div class="main__content-item_l">
          <span>Text:</span>
        </div>
        <div class="main__content-item_r">
          <p class="inp-group__text">
            *For bike transportation, found outside the box.
          </p>
        </div>
      </div>
      <div class="main__content-item">
        <div class="main__content-item_l">
          <span>Image:</span>
        </div>
        <div class="main__content-item_r">
          <media-uploader
            :key="`securing_strap_${nonce}`"
            :auto-processing="false"
            :media="value.file"
            :multiple="false"
            :processing-u-r-l="dropzoneSettings.url"
            :request-headers="dropzoneSettings.headers"
            footer-text="Drag&drop or <u>click here</u> to upload images (jpg, png 112x112 up to 5MB)"
            type="image"
            @onRemove="removeFiles"
            @onSuccessResponse="setFile($event)"
          />
        </div>
      </div>
    </div>
    <button class="field__remove" @click="removeSection"></button>
  </div>
</template>

<script>
import MediaUploader from "@/components/inputs/MediaUploader.vue";
import { API_ENDPOINT } from "@/utils/api";

export default {
  name: "SecurityStrap",
  props: ["value"],
  components: { MediaUploader },
  computed: {
    dropzoneSettings() {
      return {
        url: API_ENDPOINT + "v2/builds/media",
        autoProcessQueue: true,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      };
    },
  },
  data() {
    return {
      nonce: 1,
    };
  },
  methods: {
    removeSection() {
      this.$emit("onRemove");
    },
    setFile(response) {
      let emitted = { ...this.value };
      emitted.file = response[0];
      this.$emit("input", emitted);
      this.nonce++;
    },
    removeFiles() {
      this.nonce++;
      this.$emit("input", null);
    },
  },
};
</script>

<style scoped></style>
